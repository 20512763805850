// Chakra imports
import {
    Flex,
    Grid,
    Text,
    useColorModeValue,
    Badge,
    Select,
    Button,
    Input, 
    FormControl, 
    FormLabel, 
    FormHelperText,
    FormErrorMessage, 
    Checkbox, 
    CheckboxGroup, 
    Stack
  } from "@chakra-ui/react";
  import { FaTimes, FaTimesCircle } from "react-icons/fa";
  // Custom components
  import React, { useEffect, useState } from "react";
  import { property, upperFirst } from "lodash";
  import Card from "components/Card/Card";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
  import { useHistory, NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
  import DynamicForm from "./DynamicForm";
  import FormData from "./FormData";
  const CreateDeal = () => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const [visible, setVisible] = useState(true);
    const BLACK = "#000000";
    const GRAY = useColorModeValue("gray.300", "white");
    const {id} = useParams()
    const [MLS, setMLS] = useState("E8070466") 
    const [step, setStep] = useState('1')
    const [error, setError] = useState(false)
    const [editing, setEditing] = useState(false)
    const [dealInfo, setDealInfo] = useState({})
    const history = useHistory()
    const title = "Create New Deal"
    async function handleMLSSubmit() {
      let resp = (await (await fetch(`/api_v2/properties/${MLS}/`)).json())
      if(!resp.board_2 && !resp.board_16 && !resp.board_17) {
        console.log("WRONG MLS")
        setError(true)
      }
      else {
        setStep('2')
        console.log("Correct MLS")
        if(resp.board_2) {
          resp = resp.board_2
          console.log(resp)
        }
        else if(resp.board_16) {
          resp = resp.board_16
        }
        else {
          resp = resp.board_17
        }
        const {unitNumber, streetNumber, streetName, streetSuffix, city, state, zip} = resp.address
        setDealInfo({MLS:resp._id, address:(unitNumber?unitNumber+' ':'') + (streetNumber?streetNumber+' ':'') + (streetName) + (streetSuffix? ' ' + streetSuffix:'') + ', '  + city + ', ' + state + ', ' + zip})
        setError(false)
        setEditing(false)
      }
    }
      
    async function handleFormSubmit(formState,  auth) {
      const fetchURL = `/api/users/${id}/acceptedoffers`
      fetch(fetchURL, {
        method: "POST",
        body: JSON.stringify(formState),
        headers: { dwelly_token: auth.token },
      })
      .then(
        history.push(`/admin/profile/${id}`))
      .catch(e=>console.log(e))
    }
    
    return (
      (
        
        <Card p="16px" style={{zIndex:1}}>
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column" >
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  {title}{" "}
                  <NavLink to={`/admin/profile/${id}`} style={{position:"absolute", right:0, paddingRight:"15px"}}> 
                    <FaTimes style={{textAlign:'end'}}
                      onClick={() => {}}
                      size={30}
                      color="gray"
                    />
                  </NavLink>
                </Text>
            </Flex>
          </CardHeader>
          {visible && (
            <CardBody px="5px"> 
              <Grid gap="24px">
              <Flex align="left" mb="18px" direction="column">

                {
                    step !== "2" && <> 

                        <FormControl isInvalid={error}>

                          <Text fontWeight="bold"> 
                          Please Enter The MLS Number of the Associated Property
                          </Text>                
                          <Input
                          fontSize="md"
                          color="gray.500"
                          display={"inline-block"}
                          fontWeight="400"
                          me="10px" 
                          onChange={(e) => setMLS(e.target.value)}
                          />
                          <br/>
                        
                        <Button onClick={handleMLSSubmit} colorScheme="red" mt= '3' size='md'> 
                            Continue
                        </Button>
                        <FormErrorMessage mt='2'>Invalid MLS</FormErrorMessage>
                        </FormControl>
                    </>
                }

                {
                  step === "2" && <>
                  <FormControl isRequired isInvalid={error}>
                  
                  {
                    !editing && <>
                        <FormLabel>MLS Number</FormLabel>
                      <Stack direction={'row'}>
                        <Input variant="filled" type='text' mb='5' value={dealInfo.MLS} />
                        <Button onClick={()=>setEditing(true)} colorScheme="red" mt= '3' size='md'> 
                          Edit
                        </Button>

                      </Stack>
                    </>
                  }

                  {
                    editing && <>
                      <FormLabel>MLS Number</FormLabel>
                      <Stack m='0' p='0' direction={'row'}>
                        <Input type='text' onChange={e=>setMLS(e.target.value)} value={MLS} />
                        <Button onClick={handleMLSSubmit} colorScheme="red" mt= '3' size='md'> 
                          Continue
                        </Button>
                      </Stack>
                      <FormErrorMessage mt='2' mb='2'>Invalid MLS</FormErrorMessage>
                    </>
                  }
                  </FormControl>

                  <FormControl isRequired>
                  <FormLabel>Address</FormLabel>
                    <Input variant="filled" type='text' mb='5' value={dealInfo.address} />
                  </FormControl>

                  
                  <DynamicForm
                    formData={FormData.form}
                    onFormSubmit={handleFormSubmit}
                    inline={true}
                    btnText={"Submit"}
                    data={dealInfo} 
                    viewOnly={false}
                    />
                  </>
                
                 } 
              
              </Flex>
  
              </Grid>
            </CardBody>
          )}
        </Card>
      )
    );
  };
  
  export default CreateDeal;
