// Chakra Imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Flex,
  Icon,
  Input,
  Link,
  Switch,
  Tag,
  Text,
  Textarea,
  useColorMode,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import ResizeTextarea from "react-textarea-autosize";

import GitHubButton from "react-github-btn";
import { Separator } from "components/Separator/Separator";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { FaTwitter, FaFacebook } from "react-icons/fa";
import { useLocalStorage } from "useLocalStorage";
import { useHistory, useParams } from "react-router-dom";

export default function Configurator(props) {
  const [isLargerThan480] = useMediaQuery("(min-width: 480px)");

  const history = useHistory();
  const SMS_LENGTH = 160;
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const { secondary, isOpen, onClose, fixed, ...rest } = props;
  const [switched, setSwitched] = useState(props.isChecked);
  const [sms, setSMS] = useState("");
  const [smsArea, setSMSArea] = useState(1);
  const { colorMode, toggleColorMode } = useColorMode();
  const innerHeight = window.innerHeight;
  const smsMessagesHeight = innerHeight - smsArea * 22 - 150;
  const [messages, setMessages] = useState([]);
  const [sending, setSending] = useState(false);
  const smsView = useRef(null);
  const { id } = useParams();
  const userProfile = props.userProfile;
  console.log({ userProfile });
  // Chakra Color Mode
  let fixedDisplay = "flex";
  if (props.secondary) {
    fixedDisplay = "none";
  }

  let bgButton = useColorModeValue(
    "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)",
    "white"
  );
  let colorButton = useColorModeValue("white", "gray.700");
  const secondaryButtonBg = useColorModeValue("white", "transparent");
  const secondaryButtonBorder = useColorModeValue("gray.700", "white");
  const secondaryButtonColor = useColorModeValue("gray.700", "white");
  const settingsRef = React.useRef();
  scrollToBottomOfSMS();
  useEffect(() => {
    console.log({ auth, id });
  }, [auth, userProfile]);
  function getSMSs() {
    if (auth && userProfile) {
      const fetchURL = "/api/sms/" + userProfile.phone;
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (sms) => {
          setMessages(await sms.json());
        }
      );
    }
  }
  useEffect(() => {
    console.log({ userProfile });
  }, [userProfile]);
  useEffect(() => {
    if (isOpen) {
      getSMSs();
    } else {
      setMessages([]);
    }
  }, [isOpen]);
  useEffect(() => {
    if (!auth) {
      history.push("/auth/signin");
    }
  }, [auth]);
  function sendSMS() {
    if (auth && userProfile) {
      const toSendMessage = sms;
      setSending(true);
      messages.push({ incoming: false, message: toSendMessage });
      setMessages(messages);
      const fetchURL = "/api/sms/" + userProfile.phone;
      fetch(fetchURL, {
        method: "POST",
        body: JSON.stringify({
          message: toSendMessage,
        }),
        headers: { dwelly_token: auth.token },
      }).then(async () => {
        setSending(false);
      });
      setSMS("");
    }
  }
  function scrollToBottomOfSMS() {
    if (smsView.current)
      smsView.current.scrollTop = smsView.current?.scrollHeight;
    /*smsView.current?.scrollIntoView({
      behavior: "smooth",
      block:"end"
    })*/
  }
  function smsTextChange(e) {
    setSMS(e.target.value);
    const sh = e.target.scrollHeight;
    const line = (sh - 16) / 22;
    e.target.style.height = 0;
    e.target.style.height = e.target.scrollHeight + "px";
    scrollToBottomOfSMS();
    setSMSArea(line);
  }
  return (
    <>
      <Drawer
        isOpen={props.isOpen}
        onClose={props.onClose}
        placement={document.documentElement.dir === "rtl" ? "left" : "right"}
        finalFocusRef={settingsRef}
        blockScrollOnMount={false}
        size={isLargerThan480 ? "xs" : "full"}
      >
        <DrawerContent>
          <DrawerHeader pt="24px" px="24px">
            <DrawerCloseButton />
            <Text fontSize="xl" fontWeight="bold" mt="16px">
              Dwelly SMS Chat
            </Text>
            {false && (
              <Text fontSize="md" mb="16px">
                See your dashboard options.
              </Text>
            )}
            <Separator />
          </DrawerHeader>
          <DrawerBody w="100%" ps="24px" pe="40px">
            <Box
              ref={smsView}
              height={smsMessagesHeight + "px"}
              overflowY={"auto"}
              flex={1}
            >
              {messages.map((message) => (
                <Text my={"10px"} align={message.incoming ? "left" : "right"}>
                  <Tag size="lg">{message.message}</Tag>
                </Text>
              ))}
            </Box>
            <Flex
              flexDirection="column"
              position={"absolute"}
              bottom={0}
              paddingY={3}
              width={"90%"}
            >
              <Box>
                <Textarea
                  onChange={smsTextChange}
                  width={"73%"}
                  marginRight={"5px"}
                  colorScheme={sms.length > SMS_LENGTH ? "red" : "teal"}
                  focusBorderColor={sms.length > SMS_LENGTH ? "red" : "teal"}
                  isInvalid={sms.length > SMS_LENGTH}
                  rows={smsArea}
                  value={sms}
                ></Textarea>
                <Button
                  onClick={sendSMS}
                  position={"absolute"}
                  bottom={"29px"}
                  colorScheme="teal"
                  disabled={sms.length > SMS_LENGTH || sending}
                >
                  Send
                </Button>
              </Box>
              <Text
                textAlign={"left"}
                fontSize={"xs"}
                color={sms.length > SMS_LENGTH ? "red.400" : "gray.400"}
              >
                {sms.length}
                {sms.length > SMS_LENGTH ? " message over SMS limit." : ""}
              </Text>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
Configurator.propTypes = {
  secondary: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  fixed: PropTypes.bool,
};
