// Chakra imports
import {
  Text,Box,Heading,Badge
} from "@chakra-ui/react";


// Custom components
import React, {useEffect, useState} from "react";


const Projects = ({ offer, auth, userProfile, id}) => {
  // Chakra color mode
  const [data, setData] = useState(null);
  const [expand, setExpand] = useState(false);
  
  const resume = (userProfile.renter_resumes && userProfile.renter_resumes[offer.resume_id]) && userProfile.renter_resumes[offer.resume_id]
  
  const government_identifications = resume.government_identifications && resume.government_identifications
  const proof_of_occupations = resume.proof_of_occupations && resume.proof_of_occupations
  const credit_reports = resume.credit_reports && resume.credit_reports
  const extra_documents = resume.extra_documents && resume.extra_documents
  const allDocs = {government_identifications, proof_of_occupations, credit_reports, extra_documents}
  //Object.keys(data).map((o)=>data[o].map((doc, i)=><Text fontSize='xs' textTransform='capitalize'><a target="_blank" href={doc.url}>{o}-upload_{i}</a></Text>))
  console.log('offer',{resume})
  useEffect(()=>{
    if(expand && !data){
      getOffer()
    }
  }, [expand])
  async function getOffer(){
    const fetchURL = '/api/users/'+id+'/renterresume/'+offer.rid
    const offerDetails = await (await fetch(fetchURL,{headers:{dwelly_token:auth.token}})).json()
    console.log({offerDetails})
    setData(offerDetails)
  }
  return (
    <Box key={offer.oid}>
      <Heading size='xs' textTransform='uppercase'>
        Address: {offer.address}
      </Heading>
      {offer.property_id && <Text fontSize='xs'>
        MLS: <a target="_blank" href={"https://dwelly.ca/app/properties/"+offer.address+'-'+offer.property_id}>{offer.property_id}</a>
      </Text>} <Text fontSize='xs' textTransform='capitalize'>
        <a target="_blank" href={`/api_v2/users/${id}/offers/${offer.id}`}>Application PDF</a>
      </Text>
      {(expand)?(allDocs?
        Object.keys(allDocs).map(
          (o)=> 
          allDocs[o] && Object.keys(allDocs[o]).map(
            (i)=><Text fontSize='xs' textTransform=''><a target="_blank" href={`/api_v2/users/${userProfile._id}/renter_resumes/${offer.resume_id}/${o}/${i}`}>{allDocs[o][i].originalname}</a></Text>)):
          //Object.keys(allDocs[o]).map(
        //    (i)=><Text fontSize='xs' textTransform='capitalize'><a target="_blank" href={allDocs[o][i].location}>{o}-upload_{i}</a></Text>)):
        <Text fontSize='xs'>loading...</Text>):<Badge onClick={()=>setExpand(!expand)}>{expand?'hide details':'show details'}</Badge>}
      {expand&&<Badge onClick={()=>setExpand(!expand)}>{expand?'hide details':'show details'}</Badge>}
    </Box>
  );
};

export default Projects;
