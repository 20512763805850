// Chakra imports
import {
  Flex,
  Grid,
  useColorModeValue,
  GridItem,
  Button,
  Text,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useEffect, useState } from "react";
import { FaCube, FaPenFancy, FaCheck } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import ProfileHistory from "./components/ProfileHistory";

import Projects from "./components/Projects";
import Offers from "./components/Offers";
import Utm from "./components/Utm";
import { useLocalStorage } from "../../../useLocalStorage";
import { map, orderBy } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import FixedPlugin from "../../../components/FixedPlugin/FixedPlugin";
import Chat from "../../../components/Chat/Chat";
function showToast(type, message) {
  toast[type](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
function Profile(props) {
  const history = useHistory();
  const { id } = useParams();
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [allAgents, setAllAgents] = useState([]);
  const [userProfile, setUserProfile] = useState({ phone: id, agent: true });
  const [userBookings, setUserBookings] = useState(null);
  const [userHistory, setUserHistory] = useState(null);
  const [userUTM, setUserUTM] = useState(null);

  const [rentalQualification, setRentalQualification] = useState(null);
  const [buyerQualification, setBuyerQualification] = useState(null);
  const assignedProfile = userProfile && userProfile.agent;
  const isSuperAdmin = auth?.roles?.superAdmin;
  const [renderData, setRenderData] = useState(true);
  const [idState, setIdState] = useState(id);
  const chatDisclusure = useDisclosure();

  if (id !== idState) {
    window.location.reload(false);
  }
  console.log("id:", {
    id,
    userProfile,
    rentalQualification,
    buyerQualification,
    idState,
  });
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  console.log({ userBookings });
  useEffect(() => {
    chatDisclusure.onOpen();
  }, [auth]);

  return (
    renderData && (
      <Flex direction="column">
        <Header
          sms={true}
          id={id}
          auth={auth}
          allAgents={allAgents}
          userProfile={userProfile}
          backgroundHeader={ProfileBgImage}
          backgroundProfile={bgProfile}
          avatarImage={avatar4}
          isSuperAdmin={isSuperAdmin}
          tabs={
            !isSuperAdmin && !assignedProfile
              ? [
                  {
                    name: "Claim",
                    onClick: () => {
                      claimLead();
                    },
                    icon: <FaCheck w="100%" h="100%" />,
                  },
                ]
              : []
          }
        />
        {userProfile.phone && (
          <>
            <Portal>
              <FixedPlugin onOpen={chatDisclusure.onOpen} />
            </Portal>

            <Chat
              userProfile={userProfile}
              isOpen={chatDisclusure.isOpen}
              onClose={chatDisclusure.onClose}
              onOpaque={() => setSidebarVariant("opaque")}
              onTransparent={() => setSidebarVariant("transparent")}
            />
          </>
        )}
      </Flex>
    )
  );
}

export default Profile;
