// Chakra imports
import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import React, { useState } from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DynamicForm from "./DynamicForm";
import FormData from "./CreateUserFormData";
const CreateUser = () => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [userInfo, setUserInfo] = useState({});
  const history = useHistory();
  const title = "Create New User";
  const toast = useToast();

  async function handleFormSubmit(formState, auth) {
    const fetchURL = `/api/leads`;
    fetch(fetchURL, {
      method: "POST",
      body: JSON.stringify(formState),
      headers: { dwelly_token: auth.token },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const json = await resp.json();
          history.push(`/admin/profile/${json._id}`);
          toast({
            title: "User created.",
            description: "User has been successfully created.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "User Creation Failed.",
            description: "User could not be created. Please try again later",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
        // history.push(`/admin/profile/${id}`)
      })
      .catch((e) => console.log(e));
  }

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: "50px" }}>
      <Card p="16px" overflowX={{ sm: "scroll", xl: "hidden" }}>
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {title}{" "}
            </Text>
          </Flex>
        </CardHeader>
        <CardBody px="5px">
          <Grid gap="24px">
            <Flex align="left" mb="18px" direction="column">
              <DynamicForm
                formData={FormData.form}
                onFormSubmit={handleFormSubmit}
                inline={true}
                btnText={"Submit"}
                data={userInfo}
                viewOnly={false}
              />
            </Flex>
          </Grid>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default CreateUser;
