import {
  Flex,
  Grid,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
  Badge,
  Button
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { FaPlus } from "react-icons/fa";
import Deal from "./Deal";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

const Deals = ({ id, title, auth, userProfile}) => {

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [visible, setVisible] = useState(true);
  const [deals, setDeals] = useState(null)
  const BLACK = "#000000"
  useEffect(() => {
    if (auth) {
      getDeals();
    }
  }, [auth]);
  async function getDeals() {
    setDeals(await (await fetch(`/api/users/${userProfile.id}/acceptedoffers`, {headers: {dwelly_token:auth.token}})).json())
  }
  return (
    (deals && deals.length > 0) ?  
     (
      <Card p="16px" my="24px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
            <Flex align='center' gap={'3'}> 
              {title}{" "}
              <Badge onClick={() => setVisible(!visible)}>
                {visible ? "hide" : "expand"}
              </Badge>
              <NavLink to={`/admin/profile/${id}/deals`}>
                <FaPlus
                color={BLACK}
                size={20}
                /> 
              </NavLink>
            </Flex>
            </Text>
          </Flex>
        </CardHeader>
        {visible && (
          <CardBody px="5px">
            <Grid gap="24px">
              <Stack divider={<StackDivider />} spacing="4">
                {deals ? (
                  deals.map((deal) => (
                    <Deal
                      deal={deal}
                      id={id}
                    ></Deal >
                  ))
                ) : (
                  <Text fontSize={"sm"}>loading...</Text>
                )}
              </Stack>
            </Grid>
            
          </CardBody>
        )}
      </Card>
    )
    :
    <NavLink to={`/admin/profile/${id}/deals`}>
      <Button colorScheme="red"> 
        Add New Deal
      </Button>
    </NavLink>
  );
};

export default Deals;
