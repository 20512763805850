// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";
import { useLocalStorage } from "../../../useLocalStorage";
import routes from "../../../routes";
import moment from "moment";
import { upperFirst, sortBy, orderBy } from "lodash";
function Tables({ location }) {
  console.log({ location });
  const path = location.pathname.split("/");
  const type = path[path.length - 1];
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [leads, setLeads] = useState(null);
  const [status, setStatus] = useState(true);
  const [reload, setReload] = useState(false);
  const [allAgents, setAllAgents] = useState([]);
  const [messages, setMessages] = useState([]);
  const [agentFilter, setAgentFilter] = useState(null);
  const getLeads = async () => {};
  useEffect(() => {
    if (auth) {
      fetch("/api/viewsms", { headers: { dwelly_token: auth.token } }).then(
        async (messages) => {
          setMessages(await messages.json());
        }
      );
    }
  }, [auth]);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: "50px" }}>
      {messages ? (
        <Authors
          status={status}
          setStatus={setStatus}
          title={"Messages"}
          captions={["Phone", "Date", "Message", ""]}
          data={messages}
        />
      ) : null}
    </Flex>
  );
}

export default Tables;
