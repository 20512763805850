// Chakra Icons
import { BellIcon, SearchIcon } from "@chakra-ui/icons";
// Chakra Imports
import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
// Custom Icons
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
// Custom Components
import { ItemContent } from "components/Menu/ItemContent";
import SidebarResponsive from "../../components/Sidebar/SidebarResponsive";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes.js";
import { useLocalStorage } from "../../useLocalStorage";
export default function HeaderLinks(props) {
  const { variant, children, fixed, secondary, onOpen, ...rest } = props;
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");
  let textColor = useColorModeValue("gray.700", "white");

  const [notif, setNotif] = useState([]);
  const showDeal = window.location.href.indexOf("deals") > -1 ? true : false;

  async function fetchNotifs() {
    let notification = await fetch(`/api/notifs`, {
      method: "GET",
      headers: { dwelly_token: auth.token },
    });
    notification = await notification.json();

    const sortNotifDate = (notif1, notif2) => {
      //sort notifs in order of date
      let date1 = notif1.for_date.split("-");
      let date2 = notif2.for_date.split("-");
      for (let i = 0; i < 3; i++) {
        if (date1[i] < date2[i]) return -1;
        if (date1[i] > date2[i]) return 1;
      }
      return 0;
    };
    notification.sort(sortNotifDate);

    let todayDate = new Date();
    todayDate = todayDate.toISOString().substring(0, 10);
    notification.map((notif) => {
      if (sortNotifDate(notif, { for_date: todayDate }) === 1) {
        notif.due_status = "future";
      } else if (sortNotifDate(notif, { for_date: todayDate }) === -1) {
        notif.due_status = "past";
      } else {
        notif.due_status = "present";
      }
    });

    setNotif(notification);
  }
  useEffect(() => {
    fetchNotifs();
  }, []);

  if (secondary) {
    navbarIcon = "white";
    mainText = "white";
  }
  const settingsRef = React.useRef();
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {!showDeal && <SidebarResponsive
        logoText={props.logoText}
        secondary={props.secondary}
        routes={routes}
        // logo={logo}
        {...rest}
      />}
      {!showDeal && <SettingsIcon
        cursor="pointer"
        ms={{ base: "16px", xl: "0px" }}
        me="16px"
        ref={settingsRef}
        onClick={props.onOpen}
        color={navbarIcon}
        w="18px"
        h="18px"
      />}

      <Menu>
        <MenuButton>
          {!showDeal && <BellIcon color={navbarIcon} w="18px" h="18px" />}
        </MenuButton>
        <MenuList p="16px 8px" maxH={420} overflowY={"auto"}>
          <Flex flexDirection="column">
            {notif.length === 0 ? (
              <Text
                fontSize="14px"
                mb="5px"
                color={textColor}
                textAlign={"center"}
              >
                {" "}
                No Notifications For Now{" "}
              </Text>
            ) : (
              notif.map((notification) => {
                return (
                  <MenuItem borderRadius="8px" mb="10px" key={notification._id}>
                    <ItemContent
                      info="Follow Up With"
                      boldInfo="Reminder:"
                      notif={notif}
                      setNotif={setNotif}
                      notification={notification}
                      // aName={notification.user_name}
                      // aSrc={avatar1}
                    />
                  </MenuItem>
                );
              })
            )}
          </Flex>
        </MenuList>
      </Menu>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
