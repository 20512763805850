// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Switch, Select, Grid, GridItem
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "../../../../components/Tables/TablesTableRow4VM";
import React, {useEffect, useState} from "react";

const Authors = ({ title, captions, data, status, setStatus, allAgents, selectAgent }) => {
  //const [status, setStatus] = useState(true)
  
  const textColor = useColorModeValue("gray.700", "white");
  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p='6px 0px 22px 0px'>
        <Text fontSize='xl' color={textColor} fontWeight='bold' >
            {title}
          </Text>
                  
      </CardHeader>
      <CardBody>
        <Grid width={'100%'}>
          
          <GridItem>
            <Table variant='simple' color={textColor}>
          <Thead>
            <Tr my='.8rem' pl='0px' color='gray.400'>
              {captions.map((caption, idx) => {
                return (
                  <Th display={{sm:idx===0?'table-cell':'none', md:'table-cell'}} color='gray.400' key={idx} ps={idx === 0 ? "0px" : null}>
                    {caption}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row) => {
              return (
                <TablesTableRow
                  title={title}
                  agentInfo={captions[2]==="Agent"}
                  key={`${row.email}-${row.name}`}
                  name={row.name}
                  logo={row.logo}
                  email={row.email}
                  subdomain={row.subdomain}
                  domain={row.domain}
                  status={row.status}
                  date={row.date}
                  row={row}
                />
              );
            })}
          </Tbody>
            </Table>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default Authors;
