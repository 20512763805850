import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import signInImage from "assets/img/dwelly_bg.jpg";
import { useState, useEffect } from "react";
import { useLocalStorage } from "../../useLocalStorage";
import postData from "../../common/fetch";
import { useHistory } from "react-router-dom";
import DwellyLogo from "../../assets/img/dwelly_logo.png";

function SignIn() {
  const history = useHistory();
  const [email, setEmail] = useLocalStorage("email", "");
  const [password, setPassword] = useState("");
  const [authFail, setAuthFail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  console.log({ password, len: password.length });
  useEffect(() => {
    if (auth && auth.token && auth.roles) {
      history.push("/admin");
    }
  }, [auth]); // Only re-run the effect if count changes
  // Chakra color mode
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.400", "white");
  const login = async () => {
    setLoading(true);
    setAuthFail(false);
    try {
      const loginData = await postData(
        "/api_v2/agent/login",
        step === 0 ? { email } : { email, email_token: password }
      );
      console.log({ loginData });
      if (step === 0 && !loginData.statusCode) {
        setStep(1);
      } else if (step === 1 && !loginData.statusCode) {
        console.log("login");
        setAuth(loginData);
      } else {
        setAuthFail(true);
      }
    } catch (e) {
      console.log(e);
      setAuthFail(true);
    }
    setLoading(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      login();
      //ssubmit form
    }
  };
  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{
          sm: "initial",
          md: "75vh",
          lg: "85vh",
        }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{
          sm: "100px",
          md: "0px",
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{
            userSelect: "none",
          }}
          w={{
            base: "100%",
            md: "50%",
            lg: "42%",
          }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="48px"
            mt={{
              md: "150px",
              lg: "80px",
            }}
          >
            <img
              src={DwellyLogo}
              alt="Dwelly.ca"
              width="100px"
              height="100px"
              me="10px"
            />
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Dwelly Agent Login
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColor}
              fontWeight="bold"
              fontSize="14px"
            >
              {step === 0
                ? "Enter your email to sign in"
                : "Enter the token sent to your email to login"}
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                Email
              </FormLabel>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                borderRadius="15px"
                mb="24px"
                fontSize="sm"
                type="text"
                placeholder="Your email adress"
                size="lg"
              />
              {step === 1 && (
                <>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    Token
                  </FormLabel>
                  <Input
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setPassword(e.target.value)}
                    borderRadius="15px"
                    mb="36px"
                    maxLength={6}
                    fontSize="sm"
                    type="text"
                    placeholder="6 Digit token"
                    size="lg"
                  />
                </>
              )}
              <Button
                disabled={
                  (step === 0 ? false : !(password.length === 6)) && !loading
                }
                onClick={() => login()}
                fontSize="10px"
                type="submit"
                bg="teal.300"
                w="100%"
                h="45"
                mb="20px"
                color="white"
                mt="20px"
                _hover={{
                  bg: "teal.200",
                }}
                _active={{
                  bg: "teal.400",
                }}
              >
                {step === 0 ? "NEXT" : "SIGN IN"}
              </Button>
              {authFail ? (
                <Text mb="36px" ms="4px" color={"red"} fontSize="12px">
                  {step === 0
                    ? "Email is not of an agent"
                    : "Token provided for email is incorrect"}
                </Text>
              ) : null}
            </FormControl>
          </Flex>
        </Flex>
        <Box
          display={{
            base: "none",
            md: "block",
          }}
          overflowX="hidden"
          h="100%"
          w="40vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
