// Chakra imports
import {
  Flex,
  Grid,
  Text,
  Stack, StackDivider,
  useColorModeValue,Badge,
} from "@chakra-ui/react";


// Custom components
import React, {useEffect, useState} from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import Offer from './Offer'
const Projects = ({ title, auth, userProfile, id, utm}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [offers, setOffers] = useState(null);
  const [visible, setVisible] = useState(false)

  
  return (
    <Card p='16px' my='24px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title} <Badge onClick={()=>setVisible(!visible)}>{visible?'hide':'expand'}</Badge>
          </Text>
        </Flex>
      </CardHeader>
      {visible && <CardBody px='5px'>
        <Grid gap='5px'>
            {utm && Object.keys(utm).map((key)=><Text fontSize={'sm'}>{key}: {utm[key]}</Text>)}
        </Grid>
        
      </CardBody>}
    </Card>
  );
};

export default Projects;
