// Chakra imports
import {
  CheckIcon,
  CloseIcon,
  RepeatClockIcon,
  EmailIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Grid,
  GridItem,
  Textarea,
  Input,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Tab,
  Select,
  Badge,
} from "@chakra-ui/react";
// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar5 from "assets/img/avatars/avatar5.png";
import avatar6 from "assets/img/avatars/avatar6.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { upperCase } from "lodash";
import { useLocalStorage } from "useLocalStorage";

const ButtonText = ({ text, hideMobile }) => {
  return (
    <Text
      display={hideMobile ? { sm: "none", md: "block" } : {}}
      fontSize="sm"
      fontWeight="600"
      color="teal.300"
      alignSelf="center"
    >
      {text}
    </Text>
  );
};

const ShowingGrid = ({ getBookings, booking, assignedProfile }) => {
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [date, setDate] = useState(moment().startOf("day").add(1, "day"));
  //const [days, setDays] = useState([]);
  const [times, setTimes] = useState([]);
  const [selectedDateAndTime, setSelectedDateAndTime] = useState("");
  const { id } = useParams();

  const [edit, setEdit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("Confirm Message");
  const [rescheduleMessage, setRescheduleMessage] = useState(
    "Reschedule Message"
  );
  const textColor = useColorModeValue("gray.700", "white");
  function changeReplyStatus(booking, to_status) {
    if (auth) {
      const fetchURL = "/api/leads/" + id + "/bookings/" + booking.booking._id;
      console.log({ fetchURL });
      fetch(fetchURL, {
        method: "PUT",
        headers: { dwelly_token: auth.token },
        body: JSON.stringify({
          to_status: to_status
            ? to_status
            : confirm
            ? "approved"
            : "reschedule",
          booking_message: confirm ? selectedDateAndTime : null,
          reschedule_message: reschedule ? selectedDateAndTime : null,
        }),
      }).then(async (resp) => {
        console.log({ booking, selectedDateAndTime });
        resetRequest();
        getBookings();
      });
    }

    //resetRequest()
  }
  function resetRequest() {
    setConfirm(false);
    setReschedule(false);
    setEdit(false);
  }
  const days = [];
  //days.push(moment().format("ll"));
  for (let index = 0; index < 14; index++) {
    let day = moment().startOf("day").add(1, "day").add(index, "days");

    days.push(day);
  }
  useEffect(() => {
    const times = [];
    for (let index = 8; index <= 20; index++) {
      let time = moment(date).add(index, "hours");
      let time2 = moment(date).add(index, "hours").add(30, "minutes");
      times.push(time);
      times.push(time2);
    }
    setTimes(times);
  }, [date]);

  return (
    <Grid
      marginY={1}
      borderColor={"lightteal"}
      padding={3}
      borderWidth={"1px"}
      borderRadius={10}
      templateRows={"repeat(" + (edit ? "1" : "1") + ", 1fr)"}
      templateColumns="repeat(5, 1fr)"
      gapy={0}
      gapx={5}
    >
      <GridItem colSpan={4}>
        <Text
          fontSize="sm"
          color={textColor}
          style={{ overflow: "hidden" }}
          fontWeight="bold"
        >
          <a href={booking.url} target="_blank">
            {booking.type === "sold_property"
              ? booking.sale_or_lease === "lease"
                ? "Leased: "
                : "Sold: "
              : ""}
            {booking.address_full}
          </a>
        </Text>
        <Text fontSize="xs" color="gray.500" fontWeight="400">
          <a
            style={{ textDecoration: "underline" }}
            onClick={() => {
              navigator.clipboard.writeText(booking.id);
              console.log("Show toast");
              toast.success(`MLS id ${booking.id} copied`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }}
          >
            {booking.id}
          </a>{" "}
          $
          {booking.original_price
            ? booking.original_price.toLocaleString()
            : ""}{" "}
          <Badge
            colorScheme={
              booking.booking.status === "approved" ? "green" : "orange"
            }
          >
            {upperCase(booking.booking.status)}
          </Badge>
          <Badge colorScheme={"gray"}>
            {moment(booking.booking.created_on).format("LLL")}
          </Badge>
        </Text>
      </GridItem>
      {assignedProfile && (
        <GridItem rowSpan={1} colSpan={1}>
          <Button
            onClick={() => setEdit(!edit)}
            p="0px"
            bg="transparent"
            variant="no-hover"
          >
            <ButtonText text={"REPLY"} hideMobile={false} />
          </Button>
        </GridItem>
      )}
      {edit && !(confirm || reschedule) && (
        <GridItem colSpan={4}>
          <Button onClick={() => setConfirm(true)} bg="transparent">
            <CheckIcon
              fontSize="sm"
              fontWeight="600"
              color="teal.300"
              margin={1}
            />
            <ButtonText text={"CONFIRM"} hideMobile={true} />
          </Button>
          <Button
            onClick={() => changeReplyStatus(booking, "unavailable")}
            bg="transparent"
          >
            <CloseIcon
              fontSize="sm"
              fontWeight="600"
              color="teal.300"
              margin={1}
            />
            <ButtonText text={"CANCEL"} hideMobile={true} />
          </Button>
          <Button onClick={() => setReschedule(true)} bg="transparent">
            <RepeatClockIcon
              fontSize="xl"
              fontWeight="600"
              color="teal.300"
              margin={1}
            />
            <ButtonText text={"RE-SCHEDULE"} hideMobile={true} />
          </Button>
        </GridItem>
      )}
      {edit && (confirm || reschedule) && (
        <>
          <GridItem colSpan={3}>
            <Select
              onChange={(event) => setDate(moment(event.target.value))}
              variant="outline"
              placeholder="Choose date"
            >
              {days.map((day) => (
                <option value={day.format()}>{day.format("dddd ll")}</option>
              ))}
            </Select>
          </GridItem>
          <GridItem colSpan={2}>
            <Select
              onChange={(event) => setSelectedDateAndTime(event.target.value)}
              variant="outline"
              placeholder="Choose time"
            >
              {times.map((time) => (
                <option value={time.format()}>{time.format("LT")}</option>
              ))}
            </Select>
          </GridItem>
          <GridItem colSpan={5}>
            <Button
              onClick={() => {
                changeReplyStatus(booking);
              }}
              bg="transparent"
            >
              <EmailIcon
                fontSize="sm"
                fontWeight="600"
                color="teal.300"
                margin={1}
              />
              <ButtonText text={"SEND"} hideMobile={true} />
            </Button>
            <Button
              onClick={() => {
                resetRequest();
              }}
              bg="transparent"
            >
              <CloseIcon
                fontSize="sm"
                fontWeight="600"
                color="teal.300"
                margin={1}
              />
              <ButtonText text={"CANCEL"} hideMobile={true} />
            </Button>
          </GridItem>
        </>
      )}
    </Grid>
  );
};
const BookingDetails = ({ bookings, status, getBookings, assignedProfile }) => {
  return (
    <Flex direction="column" w="100%">
      {bookings &&
        bookings.map((booking, index) => {
          console.log({ booking });
          return (status && booking.booking.status === status) ||
            (!status &&
              (booking.booking.status === "unavailable" ||
                booking.booking.status === "deleted")) ? (
            <ShowingGrid
              assignedProfile={assignedProfile}
              getBookings={getBookings}
              key={"showing_grid_" + index}
              booking={booking}
            />
          ) : null;
        })}
    </Flex>
  );
};
const Conversations = ({
  header,
  title,
  bookings,
  getBookings,
  assignedProfile,
}) => {
  const [edit, setEdit] = useState(false);
  const [visible, setVisible] = useState(true);

  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Card p="16px">
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {header}{" "}
          <Badge onClick={() => setVisible(!visible)}>
            {visible ? "hide" : "expand"}
          </Badge>
        </Text>
      </CardHeader>
      {visible && (
        <CardBody px="5px" style={{ height: "auto", overflowY: "auto" }}>
          <Tabs width={"100%"}>
            <TabList>
              {title.map((t, index) => (
                <Tab key={"tab_key_" + index}>
                  {
                    <Text fontSize="xs" color={textColor}>
                      {t}
                    </Text>
                  }
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              <TabPanel>
                <BookingDetails
                  assignedProfile={assignedProfile}
                  getBookings={getBookings}
                  bookings={bookings}
                  status="pending"
                />
              </TabPanel>
              <TabPanel>
                <BookingDetails
                  assignedProfile={assignedProfile}
                  getBookings={getBookings}
                  bookings={bookings}
                  status="approved"
                />
              </TabPanel>
              <TabPanel>
                <BookingDetails
                  assignedProfile={assignedProfile}
                  getBookings={getBookings}
                  bookings={bookings}
                  status="reschedule"
                />
              </TabPanel>
              <TabPanel>
                <BookingDetails
                  assignedProfile={assignedProfile}
                  getBookings={getBookings}
                  bookings={bookings}
                  status=""
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      )}
    </Card>
  );
};

export default Conversations;
