// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Gravatar from "react-gravatar";
import { FaCheck } from "react-icons/fa";
import { ToastContainer, toast} from 'react-toastify'

const Header = ({
  backgroundHeader,
  backgroundProfile,
  avatarImage,
  name,
  email,
  tabs,
  userProfile,
  isSuperAdmin,
  allAgents,
  id,auth, sms
}) => {
  // Chakra color mode
  console.log({allAgents, userProfile})
  const textColor = useColorModeValue("gray.700", "white");
  const borderProfileColor = useColorModeValue(
    "white",
    "rgba(255, 255, 255, 0.31)"
  );
  const [toAgent, setToAgent] = useState("")
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const assignLead = ()=>{
    if(toAgent !== ''){
      const fetchURL = '/api/leads/'+id+"?force=true"
      fetch(fetchURL, {
        method:'POST',
        body:JSON.stringify({
          agent: toAgent
        }),
        headers:{
          dwelly_token: auth.token
        }
      }).then(()=>{
        toast.success("Lead Assigned",{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
          history.back()
          //history.push('/admin')
      }).catch(e=>{
        toast.error("Unable to assign",{
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          })
          history.back()
          //history.push('/admin')
      })
      
    }
  }
  return (
    <Box
      mb={{ sm: "105px", md: "75px", xl: "70px" }}
      borderRadius='15px'
      px='0px'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      align='center'>
      <Box
        bgImage={backgroundHeader}
        w='100%'
        h={{ sm: "200px", md: "300px" }}
        borderRadius='25px'
        bgPosition='50%'
        bgRepeat='no-repeat'
        position='relative'
        display='flex'
        justifyContent='center'>
        <Flex
          direction={{ sm: "column", md: "row" }}
          mx='1.5rem'
          maxH='330px'
          w={{ sm: "90%", xl: "95%" }}
          justifyContent={{ sm: "center", md: "space-between" }}
          align='center'
          backdropFilter='saturate(200%) blur(50px)'
          position='absolute'
          boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
          border='2px solid'
          borderColor={borderProfileColor}
          bg={backgroundProfile}
          p='24px'
          borderRadius='20px'
          transform={{
            sm: "translateY(20%)",
            md: "translateY(110%)",
            lg: "translateY(160%)",
          }}>
          <Flex
            align='center'
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "row" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}>
            
            {userProfile?<Gravatar email={userProfile.email} default="mp" size={80} style={{borderRadius:15, marginRight:22}} />:null}
            {userProfile?<Flex direction='column' maxWidth='100%' my={{ sm: "14px" }}>
              <Text
                fontSize={{ sm: "lg", lg: "xl" }}
                color={textColor}
                fontWeight='bold'
                ms={{ sm: "8px", md: "0px" }}>
                {sms?"SMS To:":userProfile.name}
              </Text>
              <Text
                fontSize={{ sm: "sm", md: "md" }}
                color={emailColor}
                fontWeight='semibold'>
                {sms?id:userProfile.email}
              </Text>
            </Flex>:null}
          </Flex>
          
          {!sms&&<Flex key={'header-fles-1'}
            direction={{ sm: "column", lg: "row" }}
            w={{ sm: "100%", md: "50%", lg: "auto" }}>
              {isSuperAdmin&&<><Select variant='outline' placeholder='Choose an agent'  onChange={()=>setToAgent(event.target.value)}>{Object.values(allAgents).map((agent, index)=><option key={'_agent_selector_'+index} selected={userProfile.agent===agent._id} value={agent._id}>{agent._id}</option>)}</Select><Button onClick={()=>assignLead()} p='0px' bg='transparent' _hover={{ bg: "none" }}>
              <Flex
                align='center'
                w={{ sm: "100%", lg: "135px" }}
                //bg='hsla(0,0%,100%,.3)'
                borderRadius='15px'
                justifyContent='center'
                py='10px'
                //boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
                //border='1px solid gray.200'
                cursor='pointer'>
                <FaCheck w='100%' h='100%' />
                <Text
                  fontSize='xs'
                  color={textColor}
                  fontWeight='bold'
                  ms='6px'>
                  Assign
                </Text>
              </Flex>
            </Button></>}
              {tabs.map((tab)=><Button onClick={tab.onClick} key={tab.name} p='0px' bg='transparent' _hover={{ bg: "none" }}>
              <Flex
                align='center'
                w={{ sm: "100%", lg: "135px" }}
                //bg='hsla(0,0%,100%,.3)'
                borderRadius='15px'
                justifyContent='center'
                py='10px'
                //boxShadow='inset 0 0 1px 1px hsl(0deg 0% 100% / 90%), 0 20px 27px 0 rgb(0 0 0 / 5%)'
                //border='1px solid gray.200'
                cursor='pointer'>
                {tab.icon}
                <Text
                  fontSize='xs'
                  color={textColor}
                  fontWeight='bold'
                  ms='6px'>
                  {tab.name}
                </Text>
              </Flex>
            </Button>)}
          </Flex>}
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;
