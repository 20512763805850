import { QuestionIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { Button, Flex, Link, Text, Input } from "@chakra-ui/react";
import SidebarHelpImage from "assets/img/SidebarHelpImage.png";
import IconBox from "components/Icons/IconBox";
import React from "react";
import { useHistory } from "react-router-dom";

export function SidebarSMS(props) {
  // Pass the computed styles into the `__css` prop
  const [phone, setPhone] = React.useState("")
  const history = useHistory();

  const { children, ...rest } = props;
  return (
    <div><Input onChange={(e)=>setPhone(e.target.value)} w={"78%"} placeholder="Phone to send sms"/><Button onClick={()=>window.location.href="/viewsms/"+phone}><ArrowRightIcon/></Button></div>
  );
}
