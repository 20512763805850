export default FormData = {
  _id: "create_user_form",
  form: [
    {
      type: "email",
      label: "Email",
      name: "email",
      required: true,
    },
    {
      type: "text",
      label: "Name",
      name: "name",
      required: false,
    },
    {
      type: "tel",
      label: "Phone Number",
      name: "phone",
      required: false,
    },
  ],
};
