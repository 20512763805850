// Chakra imports
import {
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
  Select,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Input,
} from "@chakra-ui/react";
import { FaPhone, FaEnvelope, FaUserShield, FaPencilAlt, FaWindowClose, FaSave } from "react-icons/fa";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState, useRef } from "react";
import { upperFirst } from "lodash";
import { useLocalStorage } from "../../../../useLocalStorage";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
function showToast(type, message) {
  toast[type](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
const notes_text = {
  select: "Select Note",
  called: "Called customer",
  emailed: "Emailed customer",
  viewing: "Scheduled showing",
  no_response: "No Response",
  no_show: "No Show",
  unqualified: "Unqualified (Sub options; income, credit, pet, other)",
  diff_agent: "Working with another agent",
  paused: "Paused Search",
  closed_rent: "Closed - Renter",
  closed_sale: "Closed - Buyer",
  closed_seller: "Closed - Seller",
  closed_landlord: "Closed - Landlord",
  sent_buyer_qualification: "Sent Buyer Qualification",
  sent_lease_qualification: "Sent Lease Qualification",
  submitted_lease_offer: "Submitted Lease Offer",
  user_updated: "User Updated",
};
const ProfileInformation = ({
  title,
  description,
  userProfile,
  setUserProfile,
}) => {
  // Chakra color mode
  const [auth, setAuth] = useLocalStorage("auth_v2", null);

  const statuses = ["active", "inactive"];
  const notes = [
    "select",
    "called",
    "emailed",
    "viewing",
    "no_response",
    "no_show",
    "unqualified",
    "diff_agent",
    "paused",
    "closed_rent",
    "closed_sale",
    "closed_seller",
    "closed_landlord",
  ];

  const textColor = useColorModeValue("gray.700", "white");
  const [status, setStatus] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const [date, setDate] = useState(
    new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
  ); //default scheduling date = 1 week
  const [name, setName] = useState(userProfile.name);
  const [email, setEmail] = useState(userProfile.email);
  const [emailConfirmed, setEmailConfirmed] = useState(
    userProfile.email_confirmed === true || userProfile.email_confirmed === "t"
      ? true
      : false
  );
  const [phoneConfirmed, setPhoneConfirmed] = useState(
    userProfile.phone_confirmed === true ? true : false
  );
  const [userConfirmed, setUserConfirmed] = useState(
    userProfile.user_confirmed === true ? true : false
  );
  const GREEN = "#72CC50";
  const BLACK = "#000000";
  const RED = "#ED1B2E"; 
  const [phone, setPhone] = useState(userProfile.phone);
  const [isEditing, setIsEditing] = useState(false);

  async function setNotesAPI(status) {
    if (status !== "select")
      if (auth) {
        await fetch(`/api/leads/${userProfile.id}/notes`, {
          method: "PUT",
          headers: { dwelly_token: auth.token },
          body: JSON.stringify({ status }),
        });
        window.location.reload(false);
      }
  }
  async function setStatusAPI(status) {
    if (auth && status === "active") {
      await fetch(`/api/leads/${userProfile.id}/status`, {
        method: "PUT",
        headers: { dwelly_token: auth.token },
        body: JSON.stringify({ status }),
      });
      window.location.reload(false);
    } else if (status === "inactive") {
      onOpen();
    }
  }

  async function setNotification() {
    if (auth) {
      try {
        const notification = {
          user_id: userProfile.id,
          for_date: date.toISOString().substring(0, 10),
          user_name: userProfile.name,
        };
        fetch(`/api/notifs`, {
          method: "POST",
          headers: { dwelly_token: auth.token },
          body: JSON.stringify(notification),
        });
        await fetch(`/api/leads/${userProfile.id}/status`, {
          method: "PUT",
          headers: { dwelly_token: auth.token },
          body: JSON.stringify({ status: "inactive" }),
        });
        window.location.reload(false);
      } catch (err) {
        showToast("error", "Unable to create notification");
      }
    }
  }
  useEffect(() => {
    if (userProfile && status === "") {
      setStatus(userProfile.status);
    }
  }, [userProfile]);

  function formatPhoneNumber(phoneNumber) {
    const cleanNum = phoneNumber.toString().replace(/\D/g, "");
    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        (match[2] ? "(" : "") +
        match[1] +
        (match[2] ? ") " : "") +
        (match[2] ? match[2] + (match[3] ? "-" : "") : "") +
        match[3]
      );
    }
    return cleanNum;
  }

  async function makeNonEditable() {
    setIsEditing(false);
    let newProfile = {};
    if (userProfile.name !== name.trim()) {
      newProfile.name = name.trim();
    }
    if (userProfile.email !== email.trim()) {
      newProfile.email = email.trim();
      newProfile.email_confirmed = false;
    }
    if (userProfile.phone !== phone) {
      newProfile.phone = phone;
      newProfile.phone_confirmed = false;
    }
    if (Object.keys(newProfile).length !== 0) {
      callUpdate(newProfile);
    }
  }

  async function callUpdate(newProfile) {
    await fetch(`/api/leads/${userProfile.id}`, {
      method: "PUT",
      headers: { dwelly_token: auth.token },
      body: JSON.stringify(newProfile),
    });
    let newUserProfile = JSON.parse(JSON.stringify(userProfile));
    for (let a in newProfile) {
      newUserProfile[a] = newProfile[a];
    }
    setUserProfile(newUserProfile);
    if (newProfile.email_confirmed) {
      setEmailConfirmed(newProfile.email_confirmed);
    }
    if (newProfile.phone_confirmed) {
      setPhoneConfirmed(newProfile.phone_confirmed);
    }
    if (newProfile.phone_confirmed) {
      setUserConfirmed(newProfile.user_confirmed);
    }
    showToast("success", "Changes Saved");
  }

  function togglePhoneStatus() {
    callUpdate({ phone_confirmed: !phoneConfirmed });
  }

  function toggleEmailStatus() {
    callUpdate({ email_confirmed: !emailConfirmed });
  }

  function toggleUserStatus() {
    callUpdate({ user_confirmed: !userConfirmed });
  }

  return (
    <Card p="16px" my={{ sm: "24px", xl: "0px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text
          fontSize="lg"
          color={textColor}
          fontWeight="bold"
          flexWrap={"wrap"}
        >
          <Flex align='center' gap={'3'}> 
          {title}
          {isEditing === false ? 
            <FaPencilAlt
              color={BLACK}
              onClick={() => setIsEditing(!isEditing)}
              size={20}
            /> 
            :<>
              <FaWindowClose
                color={RED}
                onClick={() => setIsEditing(!isEditing)}
                size={20}
              />
            </>
          }
          {isEditing && (
            <FaSave
                color={GREEN}
                onClick={makeNonEditable}
                size={20}
              />
          )}
          </Flex> 
        </Text>
      </CardHeader>
      <CardBody px="5px">
        <Flex direction="column">
          <Flex align="left" mb="18px" direction={isEditing ? "column" : "row"}>
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
              Full Name:{" "}
            </Text>
            {!isEditing ? (
              <Text fontSize="md" color="gray.500" fontWeight="400">
                {userProfile.name}
              </Text>
            ) : (
              <Input
                fontSize="md"
                color="gray.500"
                display={"inline-block"}
                fontWeight="400"
                me="10px"
                onChange={(e) => setName(e.target.value)}
                defaultValue={name}
              />
            )}
          </Flex>

          <Flex align="left" mb="18px" direction={isEditing ? "column" : "row"}>
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
              Email:{" "}
            </Text>
            <Text fontSize="md" color="gray.500" fontWeight="400">
              {userProfile.email}
            </Text>
          </Flex>

          {(userProfile.phone || isEditing) && (
            <Flex
              align="left"
              mb="18px"
              direction={isEditing ? "column" : "row"}
            >
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
                Phone:{" "}
              </Text>
              {!isEditing ? (
                <Text fontSize="md" color="gray.500" fontWeight="400">
                  {userProfile.phone}
                </Text>
              ) : (
                <Input
                  id="phoneInput"
                  fontSize="md"
                  color="gray.500"
                  display={"inline-block"}
                  value={phone}
                  maxlength={14}
                  fontWeight="400"
                  me="10px"
                  onChange={(e) => setPhone(formatPhoneNumber(e.target.value))}
                  defaultValue={phone}
                />
              )}
            </Flex>
          )}
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
              Status:{" "}
            </Text>
            <Text fontSize="md" color="gray.500" fontWeight="400">
              <Select
                variant="outline"
                onChange={() => setStatusAPI(event.target.value)}
                value={status}
              >
                {statuses.map((status_text, index) => (
                  <option key={"user_status_" + index} value={status_text}>
                    {upperFirst(status_text)}
                  </option>
                ))}
              </Select>
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
              Registration:{" "}
            </Text>
            <Text fontSize="md" color="gray.500" fontWeight="400">
              {userProfile.created_at.split("T")[0]}
            </Text>
          </Flex>
          <Flex align="center" mb="18px">
            <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
              Notes:{" "}
            </Text>
            <Text fontSize="md" color="gray.500" fontWeight="400">
              <Select
                variant="outline"
                onChange={() => setNotesAPI(event.target.value)}
                value={status}
              >
                {notes.map((status_text, index) => (
                  <option key={"user_status_" + index} value={status_text}>
                    {upperFirst(notes_text[status_text])}
                  </option>
                ))}
              </Select>

              <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader
                      align="center"
                      justify="center"
                      fontSize="lg"
                      fontWeight="bold"
                    >
                      Schedule Followup
                    </AlertDialogHeader>

                    <AlertDialogBody>
                      <Flex align="center" justify="center">
                        <Calendar value={date} onChange={setDate} />
                      </Flex>
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        mt="20px"
                      >
                        Set a reminder to follow up with client on{" "}
                        {date.getDate() +
                          "/" +
                          (date.getMonth() + 1) +
                          "/" +
                          date.getFullYear()}
                        ?
                      </Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                      <Button ref={cancelRef} onClick={onClose}>
                        Cancel
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => {
                          onClose();
                          setStatus("inactive");
                          setNotification();
                        }}
                        ml={3}
                      >
                        Submit
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>
            </Text>
          </Flex>
          {userProfile.notes && (
            <Flex align="center" mb="0px">
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
                Notes History:{" "}
              </Text>
            </Flex>
          )}
          {userProfile.notes &&
            userProfile.notes.map((note) => (
              <Flex align="center" mb="0px">
                <Note note={note} />
              </Flex>
            ))}
          <Flex gap={5} mt="10px" alignContent={"center"}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              Verification Status:{" "}
            </Text>
            <Tooltip
              label={phoneConfirmed ? "Verified" : "Not Verified"}
              fontSize="md"
            >
              <div>
                <FaPhone
                  color={phoneConfirmed ? GREEN : BLACK}
                  onClick={togglePhoneStatus}
                  mt="5px"
                />
              </div>
            </Tooltip>

            <Tooltip
              label={emailConfirmed ? "Verified" : "Not Verified"}
              fontSize="md"
            >
              <div>
                <FaEnvelope
                  color={emailConfirmed ? GREEN : BLACK}
                  onClick={toggleEmailStatus}
                  mt="5px"
                />
              </div>
            </Tooltip>

            <Tooltip
              label={userConfirmed ? "Trusted" : "Not Trusted"}
              fontSize="md"
              placement="bottom"
            >
              <div>
                <FaUserShield
                  color={userConfirmed ? GREEN : BLACK}
                  onClick={toggleUserStatus}
                  mt="5px"
                />
              </div>
            </Tooltip>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
const Note = ({ note }) => {
  const txt = (
    <Text fontSize="sm" color="gray.500" fontWeight="400">
      {notes_text[note.note] + ": " + moment(note.date).format("ll")}
    </Text>
  );
  let what = "";
  console.log({ note });
  if (note.note === "user_updated")
    for (let a in note.extra.old_body) {
      what =
        what +
        " " +
        upperFirst(a) +
        ' from "' +
        note.extra.old_body[a] +
        '" to "' +
        note.extra.body[a] +
        '"';
    }
  return note.who ? (
    <Tooltip
      label={
        note.note === "user_updated" ? note.who + " changed " + what : note.who
      }
    >
      {txt}
    </Tooltip>
  ) : (
    txt
  );
};

export default ProfileInformation;
