// Chakra imports
import {
  Flex,
  Grid,
  Text,
  useColorModeValue,
  Badge,
  Select,
} from "@chakra-ui/react";
import { FaTimes, FaTimesCircle } from "react-icons/fa";
// Custom components
import React, { useEffect, useState } from "react";
import { upperFirst } from "lodash";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";

const PropertyInfo = ({ title, auth, userProfile, currentProperty}) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [propertyInfo, setPropertyInfo] = useState(null);
  const [visible, setVisible] = useState(true);
  const [status, setStatus] = useState('')
  const statuses = ["pending", "processed"]
  const BLACK = "#000000";
  const GRAY = useColorModeValue("gray.300", "white");

  const {id} = useParams()
  useEffect(() => {
    if (auth) {
      getProperty()
    }
  }, [auth, currentProperty]);


  async function getProperty() {
    const resp = await (await fetch(`/api/users/${userProfile.id}/properties/${currentProperty}`, {headers: {dwelly_token:auth.token}})).json()
    setStatus(resp.status)
    setPropertyInfo(resp)
  }

  function setPropertyStatusAPI(status) {
      setStatus(status)
      fetch(`/api/users/${userProfile.id}/properties/${currentProperty}`, {
        method: "PUT",
        headers: { dwelly_token: auth.token },
        body: JSON.stringify({ status }),
      })
      
  }   
  return (
    propertyInfo && (
      
      <Card p="16px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column" >
              <Text fontSize="lg" color={textColor} fontWeight="bold">
                {title}{" "}
                <Badge onClick={() => setVisible(!visible)}>
                  {visible ? "hide" : "expand"}
                </Badge>
                <NavLink to={`/admin/profile/${id}`} style={{position:"absolute", right:0, paddingRight:"15px"}}> 
                  <FaTimes style={{textAlign:'end'}}
                    onClick={() => {}}
                    size={30}
                    color="gray"
                  />
                </NavLink>
              </Text>
          </Flex>
        </CardHeader>
        {visible && (
          <CardBody px="5px"> 
            <Grid gap="24px">
            <Flex align="left" mb="18px" direction="column">

            {propertyInfo.images.map((img, ind) => {
                const extension = img.fieldname.split('.')[1];
                return <> 
                <img key={"image_" + ind} src={`/api_v2/users/${img.key}.${extension}`} style={{marginBottom:"15px"}}/>
                </>
              }) }
            <Flex align="center" mb="18px">
              <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
                Status:{" "}
              </Text>
              <Text fontSize="md" color="gray.500" fontWeight="400">
                <Select
                  variant="outline"
                  onChange={(e)=>setPropertyStatusAPI(e.target.value)}
                  value={status}
                >
                  {statuses.map((status_text, index) => (
                    <option key={"user_status_" + index} value={status_text}>
                      {upperFirst(status_text)}
                    </option>
                  ))}
                </Select>
              </Text>
            </Flex>
              {
                Object.entries(propertyInfo).map(([key, val], ind) => {
                  key = _.startCase(key)

                  if((key !== "Images") && (key !== "Id") && (key !== "Status") ) {
                    return <Flex align="left" mb="18px" direction="row" key={ind}>
                      <Text fontSize="md" color={textColor} fontWeight="bold" me="10px">
                        {`${key}: `}
                      </Text> 
                      <Text fontSize="md" color="gray.500" fontWeight="400">
                        {val}
                      </Text>
                    </Flex>
                  }
                }) 
              }
            </Flex>

            </Grid>
          </CardBody>
        )}
      </Card>
    )
  );
};

export default PropertyInfo;
