// Chakra imports
import {
  Flex,
  Grid,
  useColorModeValue,
  GridItem,
  Button,
  Text,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useEffect, useState, useRef } from "react";
import { FaCube, FaPenFancy, FaCheck } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Conversations from "./components/Conversations";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import ProfileHistory from "./components/ProfileHistory";

import Projects from "./components/Projects";
import Offers from "./components/Offers";
import Utm from "./components/Utm";
import { useLocalStorage } from "../../../useLocalStorage";
import { map, orderBy } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import FixedPlugin from "../../../components/FixedPlugin/FixedPlugin";
import Chat from "../../../components/Chat/Chat";
import Properties from "./components/Properties";
import PropertyInfo from "./components/PropertyInfo";
import Deals from "./components/Deals";
function showToast(type, message) {
  toast[type](message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
}
function Profile(props) {
  const history = useHistory();
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [allAgents, setAllAgents] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [userBookings, setUserBookings] = useState(null);
  const [userHistory, setUserHistory] = useState(null);
  const [userUTM, setUserUTM] = useState(null);
  const [currentProperty, setCurrentProperty] = useState(null);
  const [rentalQualification, setRentalQualification] = useState(null);
  const [buyerQualification, setBuyerQualification] = useState(null);
  const assignedProfile = userProfile && userProfile.agent;
  const isSuperAdmin = auth?.roles?.superAdmin;
  const [renderData, setRenderData] = useState(false);
  const { id, propertyId, dealId } = useParams();
  const [idState, setIdState] = useState(id);
  const chatDisclusure = useDisclosure();
  const propertyRef = useRef(null);
  const [showRequests, setShowRequests] = useState(true);
  const [showOffers, setShowOffers] = useState(false);

  const [renderTaken, setRenderTaken] = useState(false);

  function scrollToProperty() {
    propertyRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  if (id !== idState) {
    window.location.reload(false);
  }
  console.log("id:", {
    id,
    userProfile,
    rentalQualification,
    buyerQualification,
    idState,
  });
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  console.log({ userBookings });
  useEffect(() => {
    if (auth && userBookings && userProfile) {
      const allBookings = map(userBookings, "sale_or_lease");
      console.log({ userBookings, allBookings, userProfile, isSuperAdmin });
      if (isSuperAdmin) {
        setRenderData(true);
      } else if (userProfile.agent && userProfile.agent === auth.user.email) {
        setRenderData(true);
      } else if (userProfile.agent) {
        setRenderTaken(true);
      } else if (!userProfile.agent && allBookings.indexOf("sale") === -1) {
        setRenderData(true);
      } else {
        setRenderTaken(true);

        console.log("superAdmin taken");
        //history.push("/admin");
      }
    }
  }, [auth, userBookings, userProfile]);
  function getBookings() {
    const fetchURL = "/api/leads/" + id + "/bookings";
    fetch(fetchURL).then(async (bookings) => {
      const b = orderBy(await bookings.json(), ["created_on"], "desc");
      console.log("ids", { b }, map(b, "property_id").join(","));
      if (b.length > 0) {
        fetch("/api0/active_properties?id=" + map(b, "property_id").join(","), {
          headers: { Authorization: "Bearer " + auth.token },
        }).then(async (properties) => {
          const json = await properties.json();
          let props = [];
          console.log("Found", { json, b });
          for (let property of json) {
            for (let booking of b) {
              if (property.id === booking.property_id) {
                property.booking = booking;
                props.push(property);
                console.log("Found", booking.property_id);
              }
            }
          }
          let sold = [];
          if (props.length < b.length) {
            fetch(
              "/api0/sold_properties?id=" + map(b, "property_id").join(","),
              {
                headers: { Authorization: "Bearer " + auth.token },
              }
            ).then(async (properties) => {
              sold = await properties.json();
              console.log({ props, b, sold });
              for (let property of sold) {
                for (let booking of b) {
                  if (property.id === booking.property_id)
                    property.booking = booking;
                }
              }
              console.log({ props, sold });
              setUserBookings(props.concat(sold));
            });
          } else {
            setUserBookings(props);
          }
        });
      } else {
        setUserBookings([]);
      }
    });
  }
  function updateUTM() {
    if (auth) {
      const fetchURL = "/api/users/" + id + "/utm";
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (data) => {
          const json = await data.json();
          setUserUTM(json.utm);
        }
      );
    }
  }
  function getHistory() {
    if (auth) {
      const fetchURL = "/api/users/" + id + "/history";
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (data) => {
          try {
            const json = (await data.json())[0];
            const history = json.history;
            console.log(json.intercom);
            const b = orderBy(history, ["date"], "desc");
            if (json.intercom) {
              const utm = json.intercom.utm;
              setUserUTM(utm);
            }

            setUserHistory(b);
          } catch (e) {
            console.log("no history");
          }
        }
      );
    }
  }
  useEffect(() => {
    if (auth && isSuperAdmin) {
      fetch("/api/agents", { headers: { dwelly_token: auth.token } }).then(
        async (agents) => {
          setAllAgents((await agents.json()).agents);
        }
      );
    }
  }, [auth]);
  useEffect(() => {
    if (propertyId) {
      setCurrentProperty(propertyId);
      setShowOffers(false);
      setShowRequests(false);
    }
  }, [propertyId]);
  useEffect(() => {
    if (propertyRef.current) {
      scrollToProperty();
    }
  }, [currentProperty]);
  useEffect(() => {
    console.log({ userHistory });
  }, [userHistory]);
  useEffect(() => {
    console.log({ auth });
    if (auth && !userProfile) {
      const fetchURL = "/api/leads/" + id;
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (profile) => {
          setUserProfile(await profile.json());
        }
      );
    }
    if (auth && !userBookings) {
      getBookings();
    }
    if (auth && !userHistory) {
      getHistory();
    }
    if (auth && !rentalQualification) {
      const fetchURL = "/api/users/" + id + "/getRentalApplicationStatus";
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (profile) => {
          setRentalQualification(await profile.json());
        }
      );
    }
    if (auth && !buyerQualification) {
      const fetchURL = "/api/users/" + id + "/getMortgageApplicationStatus";
      fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
        async (profile) => {
          setBuyerQualification(await profile.json());
        }
      );
    }
    ///users/1bc41adc-730e-4061-801f-229846d7b787/getMortgageApplicationStatus?
  }, [auth]);
  const claimLead = () => {
    if (userProfile && !userProfile.agent) {
      const fetchURL = "/api/leads/" + id;
      fetch(fetchURL, {
        method: "POST",
        body: JSON.stringify({
          agent: auth.user.email,
        }),
        headers: {
          dwelly_token: auth.token,
        },
      })
        .then(() => {
          showToast("success", "Lead Claimed");
          history.push("/admin");
        })
        .catch((e) => {
          showToast("error", "Unable to claim");
          history.push("/admin");
        });
    }
  };
  return renderTaken ? (
    <Flex direction="column">
      <Header
        id={id}
        auth={auth}
        claimed={true}
        allAgents={null}
        userProfile={userProfile}
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={null}
        isSuperAdmin={false}
        tabs={[]}
      />
    </Flex>
  ) : (
    renderData && (
      <Flex direction="column">
        <Header
          id={id}
          auth={auth}
          allAgents={allAgents}
          userProfile={userProfile}
          backgroundHeader={ProfileBgImage}
          backgroundProfile={bgProfile}
          avatarImage={avatar4}
          isSuperAdmin={isSuperAdmin}
          tabs={
            !isSuperAdmin && !assignedProfile
              ? [
                  {
                    name: "Claim",
                    onClick: () => {
                      claimLead();
                    },
                    icon: <FaCheck w="100%" h="100%" />,
                  },
                ]
              : []
          }
        />
        {userProfile ? (
          <>
            <Grid
              templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }}
              gap="22px"
            >
              <GridItem rowSpan={2} colSpan={1}>
                {(assignedProfile || isSuperAdmin) && (
                  <ProfileInformation
                    title={"Profile Information"}
                    userProfile={userProfile}
                    setUserProfile={setUserProfile}
                  />
                )}
                {userHistory && (
                  <ProfileHistory
                    title={"Browsing History"}
                    userHistory={userHistory}
                  />
                )}
                <Offers
                  title="Offers"
                  auth={auth}
                  userProfile={userProfile}
                  id={id}
                  showOffers={showOffers}
                  setShowOffers={setShowOffers}
                />
                <Deals
                  title="Deals"
                  auth={auth}
                  userProfile={userProfile}
                  currentProperty={currentProperty}
                  propertyRef={propertyRef}
                  id={id}
                />

                <Properties
                  title="Properties"
                  auth={auth}
                  userProfile={userProfile}
                  setCurrentProperty={setCurrentProperty}
                  scrollToProperty={scrollToProperty}
                  id={id}
                />

                {userUTM ? (
                  Object.keys(userUTM).length > 0 && (
                    <Utm title={"UTM"} utm={userUTM} />
                  )
                ) : (
                  <Button onClick={() => updateUTM()} colorScheme="red">
                    Obtain UTM
                  </Button>
                )}
              </GridItem>
              <GridItem rowSpan={2} colSpan={2}>
                {!propertyId && (
                  <Conversations
                    assignedProfile={assignedProfile}
                    getBookings={getBookings}
                    bookings={userBookings}
                    header="Showing Requests"
                    title={["Inbox", "Confirmed", "Waiting", "Others"]}
                    showRequests={showRequests}
                    setShowRequests={setShowRequests}
                  />
                )}
                <div ref={propertyRef}>
                  {currentProperty && (
                    <PropertyInfo
                      title="Property Information"
                      auth={auth}
                      userProfile={userProfile}
                      currentProperty={currentProperty}
                      propertyRef={propertyRef}
                    />
                  )}
                </div>
              </GridItem>
              {false && (
                <PlatformSettings
                  title={"Browsing History"}
                  subtitle1={"Sales"}
                  subtitle2={"Rental"}
                />
              )}
            </Grid>
            <Grid
              templateColumns={{ sm: "1fr", xl: "repeat(2, 1fr)" }}
              gap="22px"
            >
              <Projects
                request={() => {
                  console.log("request buyer");
                  const fetchURL =
                    "/api/users/" +
                    id +
                    "/qualification/sale/" +
                    (buyerQualification._id ? "yes" : "no");
                  fetch(fetchURL, {
                    method: "put",
                    headers: { dwelly_token: auth.token },
                  })
                    .then(async (data) => {
                      const json = await data.json();
                      console.log({ data: json });
                      if (json.status) {
                        showToast("success", "Email reminder sent");
                      } else {
                        showToast("success", "Unable to send email reminder.");
                      }
                    })
                    .catch((e) => {
                      showToast("success", "Unable to send email reminder.");
                    });
                }}
                data={buyerQualification}
                title={"Buyer Qualification"}
                description={"Architects design houses"}
              />
              <Projects
                request={() => {
                  console.log("request lease");
                  const fetchURL =
                    "/api/users/" +
                    id +
                    "/qualification/lease/" +
                    (rentalQualification._id ? "yes" : "no");
                  fetch(fetchURL, {
                    method: "put",
                    headers: { dwelly_token: auth.token },
                  })
                    .then(async (data) => {
                      const json = await data.json();
                      console.log({ data: json });
                      if (json.status) {
                        showToast("success", "Email reminder sent");
                      } else {
                        showToast("success", "Unable to send email reminder.");
                      }
                    })
                    .catch((e) => {
                      showToast("success", "Unable to send email reminder.");
                    });
                }}
                data={rentalQualification}
                title={"Lease Qualification"}
                description={"Architects design houses"}
              />
            </Grid>
          </>
        ) : (
          <div>loading...</div>
        )}
        {userProfile.phone && (
          <>
            <Portal>
              <FixedPlugin onOpen={chatDisclusure.onOpen} />
            </Portal>

            <Chat
              userProfile={userProfile}
              isOpen={chatDisclusure.isOpen}
              onClose={chatDisclusure.onClose}
              onOpaque={() => setSidebarVariant("opaque")}
              onTransparent={() => setSidebarVariant("transparent")}
            />
          </>
        )}
      </Flex>
    )
  );
}

export default Profile;
