// Chakra imports
import {
  Flex,
  Grid,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";

// Custom components
import React, { useEffect, useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import Offer from "./Offer";
const Projects = ({ title, auth, userProfile, id, showOffers:visible, setShowOffers:setVisible  }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [offers, setOffers] = useState(null);

  useEffect(() => {
    if (auth) {
      getOffers();
    }
  }, [auth]);
  async function getOffers() {
    if (userProfile.offers) {
      setOffers(Object.values(userProfile.offers));
    } else {
    }
  }
  return (
    offers &&
    offers.length > 0 && (
      <Card p="16px" my="24px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {title}{" "}
              <Badge onClick={() => setVisible(!visible)}>
                {visible ? "hide" : "expand"}
              </Badge>
            </Text>
          </Flex>
        </CardHeader>
        {visible && (
          <CardBody px="5px">
            <Grid gap="24px">
              <Stack divider={<StackDivider />} spacing="4">
                {offers ? (
                  offers.map((offer) => (
                    <Offer
                      offer={offer}
                      auth={auth}
                      id={id}
                      userProfile={userProfile}
                    ></Offer>
                  ))
                ) : (
                  <Text fontSize={"sm"}>loading...</Text>
                )}
              </Stack>
            </Grid>
          </CardBody>
        )}
      </Card>
    )
  );
};

export default Projects;
