import {
    Text,Box,Heading, useColorModeValue, Flex
  } from "@chakra-ui/react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
  import { FaPencilAlt } from "react-icons/fa";  
  const BLACK = "#000000"

  import React from "react";
  const Deal = ({deal, id}) => {
    const textColor = useColorModeValue("gray.700", "white");
    return (
      <NavLink to={`/admin/profile/${id}/deals/${deal.id}`} >
        <Box key={deal.id}>
        <Flex align='center' gap={'3'}>
            <Heading size='xs' textTransform='uppercase'>
              {`${deal.address}`} 
            </Heading>
            <NavLink to={`/admin/profile/${id}/deals/${deal.id}/edit`}>
                <FaPencilAlt
                color={BLACK}
                size={20}
                /> 
              </NavLink>
          </Flex>
            <Text color={textColor} fontSize={'sm'}>
              Sale Price: ${deal.sale_price}
            </Text>
        </Box>
      </NavLink>
  
    );
  };
  
  export default Deal;
  