// Chakra imports
import {
  Flex,
  Grid,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
  Badge,
} from "@chakra-ui/react";

import React, { useEffect, useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import Property from "./Property"
const Properties = ({ id, title, auth, userProfile}) => {
  
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [visible, setVisible] = useState(true);
  const [properties, setProperties] = useState(null)
  useEffect(() => {
    if (auth) {
      getProperties();
    }
  }, [auth]);
  async function getProperties() {
    setProperties(await (await fetch(`/api/users/${userProfile.id}/properties`, {headers: {dwelly_token:auth.token}})).json())
  }
  return (
    properties &&
    properties.length > 0 && (
      <Card p="16px" my="24px">
        <CardHeader p="12px 5px" mb="12px">
          <Flex direction="column">
            <Text fontSize="lg" color={textColor} fontWeight="bold">
              {title}{" "}
              <Badge onClick={() => setVisible(!visible)}>
                {visible ? "hide" : "expand"}
              </Badge>
            </Text>
          </Flex>
        </CardHeader>
        {visible && (
          <CardBody px="5px">
            <Grid gap="24px">
              <Stack divider={<StackDivider />} spacing="4">
                {properties ? (
                  properties.map((property) => (
                    <Property
                      key={property._id}
                      property={property}
                      id={id}
                    ></Property>
                  ))
                ) : (
                  <Text fontSize={"sm"}>loading...</Text>
                )}
              </Stack>
            </Grid>
          </CardBody>
        )}
      </Card>
    )
  );
};

export default Properties;
