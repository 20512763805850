// import
import Dashboard from "views/Dashboard/Dashboard";
import Tables from "./views/Dashboard/Tables";
import Leads from "./views/Dashboard/Leads";
import Messages from "./views/Dashboard/Messages";
import VMs from "./views/Dashboard/VMs";
import Billing from "views/Dashboard/Billing";
import RTLPage from "views/Dashboard/RTL";
import Profile from "views/Dashboard/Profile";
import SMS from "views/Dashboard/SMS";
import SignIn from "./views/Auth/SignIn.js";
import DealInfo from "views/Dashboard/Profile/components/DealInfo";
import CreateDeal from "views/Dashboard/Profile/components/CreateDeal";
import CreateUser from "views/Dashboard/Profile/components/CreateUser";
import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "./components/Icons/Icons";
import {
  FaHome,
  FaHotel,
  FaUserCheck,
  FaRegCommentDots,
  FaCommentAlt,
  FaComment,
  FaVoicemail,
  FaUserPlus,
} from "react-icons/fa";

var dashRoutes = [
  /*{
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: "/admin",
  },*/
  {
    path: "/messages",
    name: "Messages",
    icon: <FaComment color="inherit" />,
    component: Messages,
    layout: "/admin",
  },
  {
    path: "/vms",
    name: "Voicemails",
    icon: <FaVoicemail color="inherit" />,
    component: VMs,
    layout: "/admin",
  },
  {
    path: "/leads/sales",
    name: "Sales Leads",
    icon: <FaHome color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/leases",
    name: "Lease Leads",
    icon: <FaHotel color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/newSales",
    name: "New Sales Leads",
    superAdmin: true,
    icon: <FaHome color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/newLeases",
    name: "New Lease Leads",
    icon: <FaHotel color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/assigned",
    name: "All Leads",
    superAdmin: true,
    icon: <FaUserCheck color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/assigned_sales",
    name: "All Sales Leads",
    superAdmin: true,
    icon: <FaUserCheck color="inherit" />,
    component: Leads,
    layout: "/admin",
  },
  {
    path: "/leads/newUser",
    name: "Create New User",
    superAdmin: false,
    icon: <FaUserPlus color="inherit" />,
    component: CreateUser,
    layout: "/admin",
  },

  {
    name: "ACCOUNT PAGES",
    category: "account",
    state: "pageCollapse",
    redirect: "yes",

    views: [
      /*{
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },*/
      {
        path: "/vm/:id",
        name: "Voicemails",
        icon: <FaVoicemail color="inherit" />,
        component: VMs,
        layout: "/admin",
      },
      {
        path: "/profile/:id/properties/:propertyId",
        name: "Dwelly User Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/profile/:id/deals/:dealId",
        name: "Dwelly User Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: DealInfo,
        layout: "/admin",
      },
      {
        path: "/profile/:id/deals",
        name: "Dwelly User Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: CreateDeal,
        layout: "/admin",
      },
      {
        path: "/profile/:id",
        name: "Dwelly User Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
      {
        path: "/sms/:id",
        name: "Dwelly User Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: SMS,
        layout: "/admin",
      },
      {
        path: "/signin",
        name: "Sign In",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
