// // collection: form_templates
export default FormData = {
  _id: "dwelly_deal_submission",
  form: [
    {
      type: "number",
      label: "Sale Price ($)",
      name: "sale_price",
      required: true,
    },
    {
      type: "text",
      label: "Seller's Name",
      name: "seller_name",
      required: true,
    },
    {
      type: "text",
      label: "Buyer Name",
      name: "buyer_name",
      required: true,
    },
    {
      type: "checkboxGroup",
      name: "condition",
      required: true,
      options: [
        "Status Certificate",
        "Financing",
        "Lawyer Review",
        "None",
        "Other",
      ],
    },
    {
      type: "text",
      label: "Other",
      name: "condition_other",
      conditions: [{ name: "condition", value: "Other" }],
      required: true,
    },
    {
      type: "date",
      name: "acceptance_date",
      required: true,
    },
    {
      type: "date",
      name: "closing_date",
      required: true,
    },
    {
      type: "date",
      name: "expiry_date_of_all_conditions",
      required: true,
    },
    {
      type: "select",
      label: "You Are",
      name: "agent_type",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "The Listing Agent",
          value: "listing agent",
        },
        {
          label: "The Co-op Agent",
          value: "co-op agent",
        },
        {
          label: "Both",
          value: "both",
        },
      ],
    },
    {
      type: "select",
      name: "sale_type",
      label: "Sale Type",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "Dwelly Sale",
          value: "dwelly sale",
        },
        {
          label: "Dwelly Lease",
          value: "dwelly lease",
        },
        {
          label: "Personal Sale",
          value: "personal sale",
        },
        {
          label: "Personal lease",
          value: "personal lease",
        },
      ],
    },
    {
      type: "select",
      label: "Total Commission Lease",
      name: "total_commission_lease",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "1 Month's Rent",
          value: "1 month's rent",
        },
        {
          label: "1/2 Month's Rent",
          value: "1/2 month's rent",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      conditions: [
        {
          name: "sale_type",
          value: "personal lease",
        },
      ],
    },
    {
      type: "text",
      name: "total_commission_lease_other",
      conditions: [{ name: "total_commission_lease", value: "Other" }],
      required: true,
    },
    {
      type: "select",
      name: "total_commission_sale",
      label: "Total Commission Sale",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "1.25% of Sale Price",
          value: "1.25% of sale price",
        },
        {
          label: "3.5% of Sale Price",
          value: "3.5% of sale price",
        },
        {
          label: "5% of Sale Price",
          value: "5% of sale price",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      conditions: [
        {
          name: "sale_type",
          value: "personal sale",
        },
        {
          name: "sale_type",
          value: "dwelly sale",
        },
      ],
    },
    {
      type: "number",
      name: "total_commission_sale_other",
      conditions: [{ name: "total_commission_sale", value: "Other" }],
      required: true,
    },
    {
      type: "select",
      label: "Total Co-op Commission",
      name: "total_co-op_commission",
      placeholder: "Select An Option",
      required: true,
      options: [
        {
          label: "2.5% of Price Sold",
          value: "2.5% of price sold",
        },
        {
          label: "1/2 Month's Rent",
          value: "1/2 month's rent",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      conditions: [
        {
          name: "agent_type",
          value: "co-op agent",
        },
        {
          name: "agent_type",
          value: "both",
        },
      ],
    },
    {
      type: "number",
      name: "total_co-op_commission_other",
      conditions: [{ name: "total_co-op_commission", value: "Other" }],
      required: true,
    },
    {
      type: "select",
      label: "Does the Dwelly Home Buyer Rebate Apply",
      placeholder: "Select An Option",
      name: "dwelly_home_buyer_rebate",
      required: true,
      options: [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
        {
          label: "Other",
          value: "Other",
        },
      ],
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "text",
      name: "dwelly_home_buyer_rebate_other",
      conditions: [{ name: "dwelly_home_buyer_rebate", value: "Other" }],
      required: true,
    },
    {
      type: "text",
      label: "Seller's Lawyer Name and Company",
      placeholder:"",
      name: "seller_lawyer_info",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "text",
      label: "Seller's Lawyer Address",
      name: "seller_lawyer_address",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "tel",
      label: "Seller's Lawyer Phone #",
      name: "seller_lawyer_phone",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "email",
      label: "Seller's Lawyer Email",
      name: "seller_lawyer_email",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "text",
      label: "Buyer's Lawyer Name and Company",
      name: "buyer_lawyer_info",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "text",
      label: "Buyer's Lawyer Address",
      name: "buyer_lawyer_address",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "tel",
      label: "Buyer's Lawyer Phone #",
      name: "buyer_lawyer_phone",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
    {
      type: "email",
      label: "Buyer's Lawyer Email",
      name: "buyer_lawyer_email",
      required: true,
      conditions: [
        {
          name: "sale_type",
          value: "dwelly sale",
        },
        {
          name: "sale_type",
          value: "personal sale",
        },
      ],
    },
  ],
};
