// Chakra imports
import {
    Flex,
    Text,
    useColorModeValue,
    Badge,
    FormControl, 
    FormLabel, 
    Input
  } from "@chakra-ui/react";
  import { FaTimes} from "react-icons/fa";
  // Custom components
  import React, { useEffect, useState } from "react";
  import { upperFirst } from "lodash";
  import Card from "components/Card/Card";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
  import { NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
  import DynamicForm from "./DynamicForm";
  import FormData from "./FormData";
  import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
  import { isEqual } from "lodash";
  import { useLocalStorage } from "useLocalStorage";
  import Header from "./Header";
import ProfileBgImage from "assets/img/ProfileBackground.png";

  const DealInfo = () => {
    // Chakra color mode
    const textColor = useColorModeValue("gray.700", "white");
    const [dealInfo, setDealInfo] = useState(null);
    const [visible, setVisible] = useState(true);
    const BLACK = "#000000";
    const GRAY = useColorModeValue("gray.300", "white");
    const history = useHistory()
    const [auth, setAuth] = useLocalStorage("auth_v2", null);
    const { id:userId, dealId } = useParams();
    const isEditable = window.location.href.indexOf("edit") > -1 ? true : false;
    const title=isEditable?"Editing Deal Information":"Deal Information"
    const [dynamicFormData, setDynamicFormData] = useState(null);
    useEffect(() => {
      if (auth) {
        getDeal()
      }
    }, [auth]);
  
    async function handleEditForm(formState,  auth) {
      const fetchURL = `/api/users/${userId}/acceptedoffers/${dealId}`
      console.log("Edited",formState)
      if(!isEqual(formState, dealInfo)) {
        fetch(fetchURL, {
          method: "PUT",
          body: JSON.stringify(formState),
          headers: { dwelly_token: auth.token },
        })
        .then(
          history.push(`/admin/profile/${userId}`))
        .catch(e=>console.log(e))
      }
    }
    

    async function getDeal() {
      setDealInfo(await (await fetch(`/api/users/${userId}/acceptedoffers/${dealId}`, {headers: {dwelly_token:auth.token}})).json())
    }

    return (
        <>
        <Card p="16px" style={{zIndex:1}}>
          <CardHeader p="12px 5px" mb="12px">
            <Flex direction="column" >
                <Text fontSize="lg" color={textColor} fontWeight="bold">
                  {title}{" "}
                  <NavLink to={`/admin/profile/${userId}`} style={{position:"absolute", right:0, paddingRight:"15px"}}> 
                    <FaTimes style={{textAlign:'end'}}
                      onClick={() => {}}
                      size={30}
                      color="gray"
                      />
                  </NavLink>
                </Text>
            </Flex>
          </CardHeader>
          {visible && (
            <CardBody px="5px">  
              <Flex direction={'column'}>
                <FormLabel>MLS Number</FormLabel>
                  <Input variant="filled" readOnly={true} type='text' mb='5' value={dealInfo?dealInfo.MLS:""} />
                <FormControl isRequired>
                  <FormLabel>Address</FormLabel>
                  <Input variant="filled" readOnly={true} type='text' mb='5' value={dealInfo?dealInfo.address:""} />
                </FormControl>
                <DynamicForm
                  formData={FormData.form}
                  inline={true}
                  btnText={"Update"}
                  data={dealInfo} 
                  viewOnly={!isEditable}
                  onFormSubmit={handleEditForm}
                  showCancel={isEditable}
                  />
              </Flex>
            </CardBody>
          )}
        </Card>
          </>
    );
  };
  
  export default DealInfo;
  