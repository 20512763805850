// Chakra imports
import {
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
  Select,
  Badge,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
const ProfileInformation = ({ title, description, userHistory }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [visible, setVisible] = useState(false);
  return (
    <Card p="16px" my={{ sm: "24px", xl: "24px" }}>
      <CardHeader p="12px 5px" mb="12px">
        <Text fontSize="lg" color={textColor} fontWeight="bold">
          {title}{" "}
          <Badge onClick={() => setVisible(!visible)}>
            {visible ? "hide" : "expand"}
          </Badge>
        </Text>
      </CardHeader>
      {visible && (
        <CardBody px="5px">
          <Flex direction="column">
            {userHistory.map((history) => (
              <Flex align="center" mb="18px">
                <Text fontSize="sm" color={textColor} me="10px">
                  <a href={history.url} style={{ textDecoration: "underline" }}>
                    {history.title} - {history.date.substring(0, 10)}
                  </a>
                </Text>
              </Flex>
            ))}
          </Flex>
        </CardBody>
      )}
    </Card>
  );
};

export default ProfileInformation;
