// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  Stack, StackDivider,Box,Heading,
  useColorModeValue,Badge, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
} from "@chakra-ui/react";

// Assets
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar4 from "assets/img/avatars/avatar4.png";
import avatar6 from "assets/img/avatars/avatar6.png";
import imageArchitect1 from "assets/img/ImageArchitect1.png";
import imageArchitect2 from "assets/img/ImageArchitect2.png";
import imageArchitect3 from "assets/img/ImageArchitect3.png";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";

import { FaPlus } from "react-icons/fa";
import ProjectCard from "./ProjectCard";

const Projects = ({ title, description, data, request }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef()

  return (
    <Card p='16px' my='24px'>
      <CardHeader p='12px 5px' mb='12px'>
        <Flex direction='column'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {title} <Button colorScheme="red" onClick={onOpen}>Request</Button>
          </Text>
          {data && <Text fontSize='sm' color='gray.500' fontWeight='400'>
            <Badge colorScheme={data.status===1?'green':'red'}>{data.status===1?'Approved':'Not Approved'}</Badge>
          </Text>}
        </Flex>
      </CardHeader>
      <CardBody px='5px'>
        <Grid
          
          gap='24px'>
          <Stack divider={<StackDivider />} spacing='4'>
            {data && data.answers &&Object.keys(data.answers).map((key)=><Box key={key}>
              <Heading size='xs' textTransform='uppercase'>
                {key}
              </Heading>
              <Text pt='2' fontSize='sm'>
                {typeof data.answers[key] === 'boolean'?(data.answers[key]?"Yes":"No"):data.answers[key]}
              </Text>
            </Box>)}
            
          </Stack>
        </Grid>
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Request Qualification
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                No
              </Button>
              <Button colorScheme='red' onClick={()=>{
                onClose()
                request()
              }} ml={3}>
                YES
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      </CardBody>
    </Card>
  );
};

export default Projects;
