// Chakra imports
import {
  Text,Box,Heading, useColorModeValue
} from "@chakra-ui/react";

import React from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
const Property = ({id, property}) => {
  const textColor = useColorModeValue("gray.700", "white");
  const handleOnClick = () => {
    setCurrentProperty(property._id); 
    scrollToProperty()
  }
  return (
    <NavLink to={`/admin/profile/${id}/properties/${property._id}`}>
    <Box key={property._id}>
        <Heading size='xs' textTransform='uppercase'>
          {`${property.street_address}, ${property.city}`} 
        </Heading>
        <Text color={textColor} fontSize={'sm'}>
          Price Listed: ${property.price_listed}
        </Text>
    </Box>
    </NavLink>
  );
};

export default Property;
