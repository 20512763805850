import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Input as TextField,
  Button,
  Checkbox,
  CheckboxGroup,
  Stack,
  Select,
  FormControl,
  FormLabel as FormControlLabel,
  FormLabel as InputLabel,
  Box,
  FormErrorMessage,
} from "@chakra-ui/react";
import { SingleDatepicker } from "chakra-dayzed-datepicker";
import _ from "lodash";
import { useLocalStorage } from "useLocalStorage";
import { isEqual } from "lodash";
// } from "@mui/material";
// import MDButton from "components/MDButton";
// import MDDropzone from "components/MDDropzone";
// import MDAlert from "components/MDAlert";
// import { DatePicker, TimePicker, TimePickerToolbar } from "@mui/x-date-pickers";

/**
 * A dynamic form component that renders form fields based on the provided formData.
 *
 * @component
 * @param {Object[]} formData - An array of form field objects.
 * @param {string} formData[].type - The type of the form field (e.g., "text", "checkbox", "select").
 * @param {string} formData[].name - The name of the form field.
 * @param {string} formData[].label - The label for the form field.
 * @param {boolean} [formData[].required] - Indicates if the form field is required.
 * @param {Object[]} [formData[].options] - An array of options for select fields.
 * @param {string} formData[].options[].value - The value of the option.
 * @param {string} formData[].options[].label - The label for the option.
 * @param {Function} onFormSubmit - A callback function to handle form submission.
 */

const DynamicForm = ({ formData, onFormSubmit, btnText, viewOnly, data, showCancel }) => {
  const [formState, setFormState] = useState(data ? data : {});
  const [formEnabled, setFormEnabled] = useState(false);
  const [invalidForm, setInvalidForm] = useState({});
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  
  function formatPhoneNumber(phoneNumber) {
    const cleanNum = phoneNumber.toString().replace(/\D/g, "");
    const match = cleanNum.match(/^(\d{3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return (
        (match[2] ? "(" : "") +
        match[1] +
        (match[2] ? ") " : "") +
        (match[2] ? match[2] + (match[3] ? "-" : "") : "") +
        match[3]
      );
    }
    return cleanNum;
  }

  function validateEmail(str) {
    let emailValidateRegex = /^\S+@\S+\.\S+$/;
    return str.match(emailValidateRegex);
  }

  function isConditionRendered(field) {
    if (field.conditions) {
      for (let condition of field.conditions) {
        if (
          formState[condition.name] === condition.value ||
          (Array.isArray(formState[condition.name]) &&
            formState[condition.name].includes(condition.value))
        ) {
          return true;
        }
      }
    }
    return false;
  }

  const verifyFields = () => {
    let tmpForm = {};
    let isValid = true
    for (const elem of formData) {
      //dont validate if the "other"/condition field is not displayed
      if (elem.conditions && !isConditionRendered(elem)) {
        continue;
      }
      if (elem.required === true && formState[elem.name] !== undefined) {
        if (
          (elem.type === "text" ||
            elem.type === "number" ||
            elem.type === "date" ||
            elem.type === "select") &&
          (formState[elem.name] + "").length <= 0
        ) {
          tmpForm = {
            ...tmpForm,
            [elem.name]: true,
          };
        } else if (elem.type === "tel") { 
          if (formState[elem.name]?.length !== 14) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        } else if (elem.type === "checkboxGroup") {
          if (!(formState[elem.name] && formState[elem.name].length > 0)) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        } else if (elem.type === "email") {
          if (!formState[elem.name] || !validateEmail(formState[elem.name])) {
            tmpForm = { ...tmpForm, [elem.name]: true };
          }
        }
      }
      else if (elem.required){
        isValid = false
      }
    }
    setInvalidForm(tmpForm);
    return (
      isValid &&
      Object.keys(tmpForm).length === 0
    );
  };

  const handleChange = (e) => {
    let { name, value, checked, type } = e.target;

    if (type === "checkbox") {
      let checkedArr = formState[name] ?? [];
      if (checked) {
        checkedArr.push(value);
      } else {
        if (checkedArr.indexOf(value) > -1) {
          checkedArr.splice(checkedArr.indexOf(value), 1);
        }
      }
      setFormState({ ...formState, [name]: checkedArr });
    } else if (type === "tel") {
      setFormState({ ...formState, [name]: formatPhoneNumber(value) });
    } else {
      setFormState({ ...formState, [name]: value });
    }
  };

  useEffect(() => {
    setFormEnabled(verifyFields());
  }, [formState]);
  useEffect(() => {
    setFormState({ ...formState, ...data });
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onFormSubmit(formState, auth);
  };
  const renderFormField = (field) => {
    switch (field.type) {
      case "text":
      case "number":
      case "tel":
      case "email":
        return (
          <FormControl
            isRequired={field.required}
            name={field.name}
            isInvalid={invalidForm[field.name]}
          >
              <FormControlLabel>
                {field.label || _.startCase(field.name)}
              </FormControlLabel>
            <TextField
              readOnly={viewOnly}
              placeholder={field.label ? field.label : _.startCase(field.name)}
              name={field.name}
              type={field.type}
              maxlength={field.type === "tel" ? 14 : 100}
              width={"100%"}
              value={formState[field.name] || ""}
              onChange={handleChange}
            />
            <FormErrorMessage>Please enter a correct value </FormErrorMessage>
          </FormControl>
        );

      case "checkboxGroup":
        return (
          <FormControl
            isRequired={field.isRequired}
            isInvalid={invalidForm[field.name]}
          >
            <CheckboxGroup colorScheme="red" value={formState[field.name]}>
              <FormControlLabel>{_.startCase(field.name)}</FormControlLabel>
              <Stack spacing={1} direction="column">
                {field.options.map((option) => (
                    <Checkbox
                      readOnly={viewOnly}
                      size="md"
                      type="checkbox"
                      value={option}
                      name={field.name}
                      onChange={handleChange}
                    >
                    {" "}
                    {option}{" "}
                    </Checkbox>
                ))}
              </Stack>
              <FormErrorMessage>Please enter a valid option </FormErrorMessage>
            </CheckboxGroup>
          </FormControl>
        );

      case "select":
        return (
          <FormControl
            isRequired={field.required}
            isInvalid={invalidForm[field.name]}
          >
            <FormControlLabel>
              {" "}
              {_.startCase(field.label) || _.startCase(field.name)}{" "}
            </FormControlLabel>
            <Select
              disabled={viewOnly}
              label={field.label}
              required={field.required}
              name={field.name}
              placeholder={field.placeholder}
              value={formState[field.name] ?? ""}
              onChange={handleChange}
            >
              {field.options.map((option) => (
                <option
                  key={option.value ? option.value : option.label}
                  value={option.value ? option.value : option.label}
                >
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>
        );

      case "date":
        return (
          <>
            <FormControl
              isRequired={field.required}
              isInvalid={invalidForm[field.name]}
            >
              <FormControlLabel>
                {" "}
                {_.startCase(field.label) || _.startCase(field.name)}{" "}
              </FormControlLabel>
              <SingleDatepicker
                disabled={viewOnly}
                name="date-input"
                date={
                  formState[field.name]
                    ? new Date(formState[field.name])
                    : undefined
                }
                onDateChange={(date) =>
                  setFormState({
                    ...formState,
                    [field.name]: date.toISOString(),
                  })
                }
              />
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box component="form" role="form">
      {formData.map((field, index) => {
        const isCondRendered = isConditionRendered(field);

        //if 'other' field exists and not rendered, then delete its value in the state to prevent its dependencies to also render
        if (field.conditions && !isCondRendered) {
          delete formState[field.name];
        } else if (!field.conditions || isCondRendered) {
          return (
            <Box
              key={index}
              mb={2}
              w={{ base: "250px", md: "400px", lg: "550px" }}
            >
              {renderFormField(field)}
            </Box>
          );
        }
      })}
      {
        !viewOnly &&
      <Button disabled={!formEnabled} onClick={handleSubmit} type="submit">
        {btnText ? btnText : "Submit"}
      </Button>
      }
      {
        showCancel && (
          <Button ml='5px' disabled={isEqual(formState, data)} onClick={()=>setFormState({...data})}> Cancel </Button>
        )
      }
    </Box>
  );
};

DynamicForm.propTypes = {
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      required: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  data: PropTypes.object,
};

export default DynamicForm;
