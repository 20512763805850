// Chakra imports
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Authors from "./components/Authors";
import Projects from "./components/Projects";
import { tablesTableData, dashboardTableData } from "variables/general";
import { useLocalStorage } from "../../../useLocalStorage";
import routes from "../../../routes";
import moment from "moment";
import { upperFirst, sortBy, orderBy } from "lodash";
import SalesOverview from "./components/SalesOverview";
import LineChart from "components/Charts/LineChart";
import { lineChartData, lineChartOptions } from "variables/charts";

function Tables({ location }) {
  console.log({ location });
  const path = location.pathname.split("/");
  const type = path[path.length - 1];
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const [leads, setLeads] = useState(null);
  const [status, setStatus] = useState(true);
  const [reload, setReload] = useState(false);
  const [allAgents, setAllAgents] = useState([]);
  const [agentFilter, setAgentFilter] = useState(null);
  const getLeads = async () => {};
  useEffect(() => {
    if (auth) {
      if (
        type === "assigned_sales" ||
        type === "assigned_leases" ||
        type === "assigned"
      ) {
        fetch("/api/agents", { headers: { dwelly_token: auth.token } }).then(
          async (agents) => {
            setAllAgents((await agents.json()).agents);
          }
        );
      }
    }
  }, [auth]);
  useEffect(() => {
    console.log({ auth, leads, reload });
    if (auth && reload) {
      if (reload) setReload(false);
      if (
        type === "assigned_sales" ||
        type === "assigned_leases" ||
        type === "assigned" ||
        type == "newLeases" ||
        type == "newSales" ||
        type === "sales" ||
        type === "leases"
      ) {
        let fetchURL =
          type.indexOf("new") >= 0
            ? type === "newSales"
              ? "/api/leads?type=sales"
              : "/api/leads?type=leases"
            : "/api/leads/" + type + "/agent/" + auth.user.email;
        if (type === "assigned") {
          fetchURL =
            "/api/leads?type=assigned" +
            (agentFilter ? "&agent=" + agentFilter : "");
        }
        if (type === "assigned_sales") {
          fetchURL =
            "/api/leads?type=assigned&filter=sale" +
            (agentFilter ? "&agent=" + agentFilter : "");
        }
        if (type === "assigned_leases") {
          fetchURL =
            "/api/leads?type=assigned&filter=lease" +
            (agentFilter ? "&agent=" + agentFilter : "");
        }
        if (!status) {
          if (fetchURL.indexOf("?") >= 0) {
            fetchURL = fetchURL + "&status=inactive";
          } else {
            fetchURL = fetchURL + "?status=inactive";
          }
        }
        fetch(fetchURL, { headers: { dwelly_token: auth.token } }).then(
          async (leads) => {
            let l = await leads.json();
            //l.leads = orderBy(l.leads, ["lead_assigned_on"], ["desc"]);
            console.log({ l });
            setLeads(l);
          }
        );
      }
    }
  }, [reload]);
  useEffect(() => {
    console.log({ leads });
  }, [leads]);
  useEffect(() => {
    console.log({ auth, status });
    setReload(true);
  }, [status]);
  function selectAgent(t) {
    setAgentFilter(t !== "" ? t : null);
    setReload(true);
  }
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", sm: "50px" }}>
      {false && (
        <SalesOverview
          title={"Registrations"}
          percentage={5}
          chart={
            <LineChart
              lineChartOptions={lineChartOptions}
              lineChartData={lineChartData}
            />
          }
        />
      )}
      {false && (
        <SalesOverview
          title={"Bookings"}
          percentage={5}
          chart={
            <LineChart
              lineChartOptions={lineChartOptions}
              lineChartData={lineChartData}
            />
          }
        />
      )}
      {leads ? (
        <Authors
          selectAgent={selectAgent}
          allAgents={allAgents}
          status={status}
          setStatus={setStatus}
          title={upperFirst(type.replace("new", "New ")) + " Leads"}
          captions={["Name", "Date", type === "assigned" ? "Agent" : ""]}
          data={leads.leads}
        />
      ) : null}
    </Flex>
  );
}

export default Tables;
