// Chakra imports
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Switch,
  Select,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import TablesTableRow from "../../../../components/Tables/TablesTableRow";
import React, { useEffect, useState } from "react";
import _ from "lodash";
const Authors = ({
  title,
  captions,
  data,
  status,
  setStatus,
  allAgents,
  selectAgent,
}) => {
  //const [status, setStatus] = useState(true)
  const [searchText, setSearchText] = useState("");
  const [filtered, setFiltered] = useState(data);
  const textColor = useColorModeValue("gray.700", "white");
  const search = (txt) => {
    return _.lowerCase(txt).indexOf(searchText) > -1;
  };
  useEffect(() => {
    if (searchText !== "") {
      setFiltered(
        _.filter(data, (d) => {
          return search(d.name) || search(d.email);
        })
      );
    }
  }, [searchText]);
  useEffect(() => {
    if (data) {
      setFiltered(data);
    }
  }, [data]);
  return (
    <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
      <CardHeader p="6px 0px 22px 0px">
        <Text fontSize="xl" color={textColor} fontWeight="bold">
          {title.replace("_l", " L").replace("_s", " S")} - Showing{" "}
          {status ? "Active" : "Inactive"}
        </Text>

        <Switch
          value={status}
          isChecked={!!status}
          onChange={(event) => setStatus(!status)}
          float={"right"}
          colorScheme={"teal"}
          paddingLeft={5}
          size="lg"
        ></Switch>
      </CardHeader>
      <CardBody>
        <Grid width={"100%"}>
          <GridItem>
            <Input
              id="search-text"
              value={searchText}
              my={"1"}
              placeholder="Search ..."
              onChange={(e) => setSearchText(e.target.value)}
            />
          </GridItem>
          <GridItem>
            {allAgents && allAgents.length > 0 && (
              <Select
                variant="outline"
                placeholder="Filter by agent"
                defaultValue={""}
                onChange={() => selectAgent(event.target.value)}
              >
                <option key={"_agent_selector__1"} value={""}>
                  Show All Agent Leads
                </option>
                {Object.values(allAgents).map((agent, index) => (
                  <option key={"_agent_selector_" + index} value={agent._id}>
                    {agent._id}
                  </option>
                ))}
              </Select>
            )}
          </GridItem>
          <GridItem>
            <Table variant="simple" color={textColor}>
              <Thead>
                <Tr my=".8rem" pl="0px" color="gray.400">
                  {captions.map((caption, idx) => {
                    return (
                      <Th
                        color="gray.400"
                        key={idx}
                        ps={idx === 0 ? "0px" : null}
                      >
                        {caption}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {filtered.map((row) => {
                  return (
                    <TablesTableRow
                      agentInfo={captions[2] === "Agent"}
                      key={`${row.email}-${row.name}`}
                      name={row.name}
                      logo={row.logo}
                      email={row.email}
                      subdomain={row.subdomain}
                      domain={row.domain}
                      status={row.status}
                      date={row.date}
                      row={row}
                    />
                  );
                })}
              </Tbody>
            </Table>
          </GridItem>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default Authors;
