// chakra imports
import {
  Avatar,
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { ClockIcon } from "components/Icons/Icons";
import PropTypes from "prop-types";
import React from "react";
import { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useLocalStorage } from "../../useLocalStorage";

export function ItemContent(props) {
  const navbarIcon = useColorModeValue("gray.500", "gray.200");
  const notificationColor = useColorModeValue("gray.700", "white");
  const pastTimeColor = useColorModeValue("red.400", "red.400");
  const futureTimeColor = useColorModeValue("gray.500", "gray.200");
  const presentTimeColor = useColorModeValue("green.400", "green.400");

  const notifColor =
    props.notification.due_status === "present"
      ? presentTimeColor
      : props.notification.due_status === "past"
      ? pastTimeColor
      : futureTimeColor;

  const spacing = " ";
  const cancelRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [auth, setAuth] = useLocalStorage("auth_v2", null);
  const isSuperAdmin = auth?.roles?.superAdmin;
  const resolveNotif = (notif_id) => {
    fetch(`/api/notifs/${notif_id}`, {
      method: "PUT",
      headers: { dwelly_token: auth.token },
      body: JSON.stringify({ status: "completed" }),
    });
  };

  return (
    <>
      {/* <Avatar
        name={props.aName}
        src={props.aSrc}
        borderRadius="12px"
        me="16px"
      /> */}
      <Flex flexDirection="column">
        <Text fontSize="14px" mb="5px" color={notificationColor}>
          <Text fontWeight="bold" fontSize="14px" as="span">
            {props.boldInfo}
            {spacing}
          </Text>
          <NavLink to={"/admin/profile/" + props.notification.user_id}>
            {props.info} {spacing} {props.notification.user_name}
          </NavLink>
        </Text>
        <Flex alignItems="center">
          <ClockIcon
            color={notifColor}
            w="13px"
            h="13px"
            me="3px"
            onClick={() => onOpen()}
          />
          <Text
            fontSize="xs"
            lineHeight="100%"
            color={notifColor}
            onClick={() => onOpen()}
          >
            {props.notification.for_date}
          </Text>

          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader
                  align="center"
                  justify="center"
                  fontSize="lg"
                  fontWeight="bold"
                >
                  Clear Notification
                </AlertDialogHeader>

                <AlertDialogBody>
                  <Text
                    fontSize="md"
                    color={notificationColor}
                    fontWeight="bold"
                    mt="20px"
                  >
                    Do you want to mark this notification as resolved?
                  </Text>
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    colorScheme="red"
                    onClick={() => {
                      resolveNotif(props.notification._id);
                      onClose();
                      props.setNotif(
                        props.notif.filter(
                          (notif) => notif !== props.notification
                        )
                      );
                    }}
                    ml={3}
                  >
                    Confirm
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Flex>

        {isSuperAdmin && (
          <Text fontSize="14px" mb="5px" mt="5px" color={notificationColor}>
            For: {props.notification.agent_id}
          </Text>
        )}
      </Flex>
    </>
  );
}
