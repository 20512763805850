// Chakra imports
import { ChakraProvider, Input, Portal, useDisclosure, Text } from '@chakra-ui/react';
import Configurator from '../components/Configurator/Configurator';
import Chat from '../components/Chat/Chat';
import Footer from 'components/Footer/Footer.js';
// Layout components
import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import Sidebar from '../components/Sidebar';
import React, { useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from 'routes.js';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// Custom Chakra theme
import theme from 'theme/theme.js';
import FixedPlugin from '../components/FixedPlugin/FixedPlugin';
// Custom components
import MainPanel from '../components/Layout/MainPanel';
import PanelContainer from '../components/Layout/PanelContainer';
import PanelContent from '../components/Layout/PanelContent';
import PathMatch from 'path-match'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const route = PathMatch({
	// path-to-regexp options
	sensitive: false,
	strict: false,
	end: false,
});
export default function Dashboard(props) {
	const { ...rest } = props;
	console.log({props})
	// states and functions
	const [ sidebarVariant, setSidebarVariant ] = useState('transparent');
	const [ fixed, setFixed ] = useState(false);
	const showDeal = window.location.href.indexOf("deals") > -1 ? true : false;

	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else if (routes[i].category) {
				let categoryActiveRoute = getActiveRoute(routes[i].views);
				if (categoryActiveRoute !== activeRoute) {
					return categoryActiveRoute;
				}
			} else {
				var match = route(routes[i].layout + routes[i].path);
				var params = match(props.location.pathname)
				if (params) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};
	const ar = getActiveRoute(routes)
	console.log({ar})
	// This changes navbar state(fixed or not)
	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].views);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				var match = route(routes[i].layout + routes[i].path);
				var params = match(props.location.pathname)
				if (params) {
					if (routes[i].secondaryNavbar) {
						return routes[i].secondaryNavbar;
					}
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return getRoutes(prop.views);
			}
			if (prop.category === 'account') {
				return getRoutes(prop.views);
			}
			if (prop.layout === '/admin') {

				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
			} else {
				return null;
			}
		});
	};
	const { isOpen, onOpen, onClose } = useDisclosure();
	const chatDisclusure = useDisclosure();
	document.documentElement.dir = 'ltr';
	// Chakra Color Mode
	return (
		<ChakraProvider theme={theme} resetCss={false}>
			{!showDeal && <Sidebar
				routes={routes}
				logoText={'Dwelly Realty Inc'}
				display='none'
				sidebarVariant={sidebarVariant}
				{...rest}
			/>}
			
			<MainPanel
				w={{
					base: '100%',
					xl: 'calc(100% - 275px)'
				}}>
				<ToastContainer/>
				<Portal>
					<AdminNavbar
						onOpen={onOpen}
						logoText={'Dwelly Realty Inc'}
						brandText={getActiveRoute(routes)}
						secondary={getActiveNavbar(routes)}
						fixed={fixed}
						{...rest}
					/>
					
				</Portal>
				{getRoute() ? (
					<PanelContent>
						<PanelContainer>
							<Switch>
								{getRoutes(routes)}
								<Redirect from='/admin' to='/admin/leads/sales' />
							</Switch>
						</PanelContainer>
					</PanelContent>
				) : null}
				<Footer />
				
				<Configurator
					secondary={getActiveNavbar(routes)}
					isOpen={isOpen}
					onClose={onClose}
					
					onOpaque={() => setSidebarVariant('opaque')}
					onTransparent={() => setSidebarVariant('transparent')}
				/>
				
			</MainPanel>
			
		</ChakraProvider>
	);
}
